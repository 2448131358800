import React from "react";
import '../../CSS/Team.css';

export const Team = (props) => {
  return (
    <div id="team" className="team-section-container">
      <h3>Meet the Team</h3>
      <hr />
      <p className="team-section-description">
        We’re a dedicated team passionate about improving the domestic
        workforce and supporting families. Supported by an experienced
        Advisory Board with expertise in technology, human resources, and
        women’s employment, we are committed to making Nannies Desk the
        go-to platform for domestic help solutions.
      </p>
      <div className={"team-card-section"}>
        {props.data
          ? props.data.map((d, i) => (
            <div
              key={`${d.name}-${i}`}
              className="team-card"
            >
              <div className="team-card-thumbnail">
                <img src={d.img} alt="..." className="team-card-image" />
                <div className="team-card-caption">
                  <p>{d.name}</p>
                  <p>{d.job}</p>
                </div>
              </div>
            </div>
          ))
          : "loading"}
      </div>
    </div>
  );
};
