import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { postDataToAPI, setLocalStorageVariable } from "../Shared/shared";

const AutoLogout = () => {
  const [idleTime, setIdleTime] = useState(0);
  const navigate = useNavigate();
  const INACTIVITY_LIMIT = 20 * 60 * 1000; // 20 minutes in milliseconds

  useEffect(() => {
    const resetIdleTime = () => setIdleTime(0);

    // Add event listeners for user activity
    window.addEventListener("mousemove", resetIdleTime);
    window.addEventListener("keydown", resetIdleTime);
    window.addEventListener("click", resetIdleTime);
    window.addEventListener("scroll", resetIdleTime);

    const checkIdleTime = setInterval(() => {
      setIdleTime((prevTime) => {
        if (prevTime >= INACTIVITY_LIMIT) {
          handleConfirm(); // Call the logout function when the limit is reached
        }
        return prevTime + 1000;
      });
    }, 1000);

    return () => {
      // Clean up event listeners and interval
      window.removeEventListener("mousemove", resetIdleTime);
      window.removeEventListener("keydown", resetIdleTime);
      window.removeEventListener("click", resetIdleTime);
      window.removeEventListener("scroll", resetIdleTime);
      clearInterval(checkIdleTime);
    };
  }, []);

  const handleConfirm = async () => {
    // End Session from the API
    const formData = new FormData();
    formData.append("email", localStorage.getItem("userEmail"));
    formData.append("action", "endSession");
    const response = await postDataToAPI("/sessions.php", formData);
    if (response) {
      // Clear cache
      setLocalStorageVariable("userEmail", "");
      setLocalStorageVariable("userDetails", "");
      setLocalStorageVariable("userLocation", "");

      alert("You have been logged out due to inactivity.");
      navigate("/login");
    }
  };

  return null; // This component doesn't render anything
};

export default AutoLogout;
