import React, { useEffect, useRef } from "react";
import "../../CSS/Testimonials.css";

const testimonialsArray = [
  {
    reviewerName: "Beryl Atieno",
    role: "House help",
    organization: "3docean",
    review:
      "Nannies Desk made my job search so easy! I got connected with a kind family in no time. The app is simple to use, and I feel supported. Thank you for giving people like me a chance to grow and work safely",
  },
  {
    reviewerName: "Val Macharia",
    role: "Home owner",
    organization: "3docean",
    review: "I found a great house help through Nannies Desk, and it was so easy! The app let me choose from vetted profiles, and within a day, I had someone reliable and experienced working in my home. It saved me so much time and stress. Highly recommend it to anyone looking for trustworthy help!",
  },
  {
    reviewerName: "Victor Murambakania",
    role: "Gardener",
    organization: "3docean",
    review: "Thanks to Nannies Desk, I found a great gardening job quickly. The process was smooth, and the app was easy to use. I’m happy to work with a family that values my skills. This platform is a game-changer for gardeners like me!",
  },
];

const ReviewCard = ({ data }) => {
  return (
    <div className="review-card">
      <div className={"reviewer-details"}>
        <div className={"reviewer-image"} />
        <div className={"reviewer-name-role"}>
          <p>{data.reviewerName}</p>
          <p>
            {data.role}
          </p>
        </div>
      </div>
      <div className={"review"}>{data.review}</div>
    </div>
  );
};

export const Testimonials = () => {
  const carouselRef = useRef(null);

  useEffect(() => {
    if (window.Flickity) {
      const flickity = new window.Flickity(carouselRef.current, {
        cellAlign: "left",
        contain: true,
        wrapAround: true,
        pageDots: false,
        prevNextButtons: false,
        draggable: true,
        selectedAttraction: 0.01,
        friction: 0.15,
        initialIndex: 1,
        adaptiveHeight: true,
      });

      return () => {
        flickity.destroy();
      };
    }
  }, []);

  return (
    <div className="testimonials-container">
      <div className={"testimonials-container-inner"}>
        <h3>Our User Review</h3>
      </div>
      <div className="carousel testimonials-cards-container" ref={carouselRef}>
        {testimonialsArray.map((data, index) => (
          <ReviewCard key={index} data={data} />
        ))}
      </div>
    </div>
  );
};
