import React, { useEffect, useState } from "react";
import { getLocalStorageVariable } from "./NavBar";
import { Chef, Gardener, houseHelp, userSkills } from "../Shared/sharedLists";
import {
  Autocomplete,
  Checkbox,
  TextField,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  Tooltip,
  Button,
  CssBaseline,
} from "@mui/material";
import { postDataToAPI, safeJsonParse } from "../Shared/shared";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import JobApplicationModalConfirmation from "./JobApplicationModal";

function CompleteProfile() {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state || {};
  const jobId = data.jobId || "";

  const registrationType = getLocalStorageVariable("role");
  const userEmail = getLocalStorageVariable("userEmail");
  const [displayTheJobConfirmationModal, setDisplayTheJobConfirmationModal] =
    useState(false);
  const [nextOfKin, setNextOfKin] = useState(() => {
    // Get nextOfKin from localStorage and parse it to an array
    const storedData = localStorage.getItem("nextOfKin");

    // If data exists, parse it; otherwise, use the default array
    return storedData
      ? JSON.parse(storedData)
      : [
          { fullName: "", relationShip: "", phoneNumber: "" },
          { fullName: "", relationShip: "", phoneNumber: "" },
        ];
  });
  const [referees, setReferees] = useState(() => {
    // Get referees from localStorage and parse it to an array
    const storedData = localStorage.getItem("referees");

    // If data exists, parse it; otherwise, use the default array
    return storedData
      ? JSON.parse(storedData)
      : [
          { fullName: "", phoneNumber: "" },
          { fullName: "", phoneNumber: "" },
        ];
  });

  const [profileData, setProfileData] = useState(() => {
    const storedData = localStorage.getItem("profileData");
    return storedData
      ? JSON.parse(storedData)
      : {
          availability: "",
          yearsOfExperience: "",
          description: "",
          preferredAgeGroups: [],
          languagesSpoken: "",
          currentEmploymentStatus: "",
          criminalRecord: "",
          criminalRecordExplanation: "",
          idPicture: null,
          idBackPicture: null,
          certificatePicture: null,
          skills: [],
        };
  });

  const [errors, setErrors] = useState({});

  const requiredFields = [
    "availability",
    "yearsOfExperience",
    "description",
    "languagesSpoken",
    "currentEmploymentStatus",
    "criminalRecord",
    ...(profileData.criminalRecord === "yes"
      ? ["criminalRecordExplanation"]
      : []),
  ];

  useEffect(() => {
    localStorage.setItem("profileData", JSON.stringify(profileData));
  }, [profileData]);

  useEffect(() => {
    localStorage.setItem("referees", JSON.stringify(referees));
    localStorage.setItem("nextOfKin", JSON.stringify(nextOfKin));
  }, [referees, nextOfKin]);

  const handleProfileDataChange = (field, value) => {
    setProfileData((prevData) => ({ ...prevData, [field]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [field]: false }));
  };

  const validateFields = () => {
    const newErrors = {};
    requiredFields.forEach((field) => {
      if (!profileData[field]) {
        newErrors[field] = true;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const displayJobApplicationConfirmationModal = () => {
    setDisplayTheJobConfirmationModal(true);
  };

  const handleCompleteProfileSetup = async (e) => {
    e.preventDefault();

    if (!validateFields()) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Please fill out all required fields.",
      });
      return;
    }

    const formData = new FormData();
    Object.entries(profileData).forEach(([key, value]) => {
      if (key === "skills" || key === "preferredAgeGroups") {
        formData.append(key, JSON.stringify(value));
      } else if (value !== null && value !== undefined) {
        formData.append(key, value);
      }
    });
    formData.append("referees", JSON.stringify(referees));
    formData.append("nextOfKin", JSON.stringify(nextOfKin));
    formData.append("email", userEmail);

    try {
      const response = await postDataToAPI(
        "/dashboard/completeProfile.php",
        formData
      );
      if (response.status === "success") {
        if (jobId == 0) {
          //Clear Local Storage
          localStorage.removeItem("profileData");
          localStorage.removeItem("referees");
          localStorage.removeItem("nextOfKin");

          Swal.fire({
            title: "Success!",
            text: "Profile Updated successfully!",
            icon: "success",
            timer: 2000, // 3 seconds
            showConfirmButton: false,
            willClose: () => {
              navigate("/profile");
            },
          });
        } else {
          //Clear Local Storage
          localStorage.removeItem("profileData");
          localStorage.removeItem("referees");
          localStorage.removeItem("nextOfKin");

          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Profile saved successfully!",
          });
          displayJobApplicationConfirmationModal();
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.message || "Failed to save profile.",
        });
      }
    } catch {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while saving your profile.",
      });
    }
  };

  const skillChoice =
    registrationType === Chef
      ? userSkills.chef_skills
      : registrationType === houseHelp
      ? userSkills.nanny_skills
      : registrationType === Gardener
      ? userSkills.gardener_skills
      : [];

  const handleNextOfKinChange = (index, field, value) => {
    const updatedNextOfKin = [...nextOfKin];
    updatedNextOfKin[index][field] = value;
    setNextOfKin(updatedNextOfKin);
  };

  const handleRefereesChange = (index, field, value) => {
    const updatedReferees = [...referees];
    updatedReferees[index][field] = value;
    setReferees(updatedReferees);
  };

  //UseEffect to check If User had Already Completed Profile Before
  useEffect(() => {
    if (jobId == 0) {
      return;
    }

    const checkIfProfileFilled = async () => {
      const formData = new FormData();
      formData.append("userEmail", userEmail);

      const response = await postDataToAPI(
        "/dashboard/checkIfUserHasCompleatedProfile.php",
        formData
      );
      console.log("Res: ", response);
      if (response === "success") {
        //Display Job Application Confirmation Modal
        displayJobApplicationConfirmationModal();
      }
    };

    checkIfProfileFilled();
  }, []);

  const handleCloseJobConfirmationModal = () => {
    setDisplayTheJobConfirmationModal(false);
  };

  const fetchAdditionalInfoData = async () => {
    const userEmail = getLocalStorageVariable("userEmail");
    const formData = new FormData();
    formData.append("email", userEmail);

    try {
      const user = await postDataToAPI(
        "/dashboard/fetchMoreInfoData.php",
        formData
      );

      if (user) {
        console.log("User: ", user);

        // Parse nextOfKin JSON or use a default empty array
        const parsedNextOfKin = safeJsonParse(JSON.parse(user.nextOfKin)) || [];
        const nextOfKinData = [
          {
            fullName: parsedNextOfKin[0]?.fullName || "",
            relationShip: parsedNextOfKin[0]?.relationShip || "",
            phoneNumber: parsedNextOfKin[0]?.phoneNumber || "",
            email: parsedNextOfKin[0]?.email || "",
          },
          {
            fullName: parsedNextOfKin[1]?.fullName || "",
            relationShip: parsedNextOfKin[1]?.relationShip || "",
            phoneNumber: parsedNextOfKin[1]?.phoneNumber || "",
            email: parsedNextOfKin[1]?.email || "",
          },
        ];

        // Parse referees JSON or use a default empty array
        const parsedReferees = safeJsonParse(JSON.parse(user.referees)) || [];
        const refereesData = [
          {
            fullName: parsedReferees[0]?.fullName || "",
            phoneNumber: parsedReferees[0]?.phoneNumber || "",
          },
          {
            fullName: parsedReferees[1]?.fullName || "",
            phoneNumber: parsedReferees[1]?.phoneNumber || "",
          },
        ];

        // Parse other JSON fields
        const preferredAgeGroupsData =
          safeJsonParse(user.preferredAgeGroups) || [];
        const skillsData = safeJsonParse(JSON.parse(user.skills)) || [];

        // Set states with parsed and reconstructed data
        setNextOfKin(nextOfKinData);
        setReferees(refereesData);

        setProfileData((prevData) => ({
          ...prevData,
          availability: user.availability || "",
          yearsOfExperience: user.yearsOfExperience || "",
          description: user.description || "",
          preferredAgeGroups: preferredAgeGroupsData,
          languagesSpoken: user.languagesSpoken || "",
          currentEmploymentStatus: user.currentEmploymentStatus || "",
          criminalRecord: user.criminalRecord || "",
          criminalRecordExplanation: user.criminalRecordExplanation || "",
          idPicture: user.idPicture || null,
          certificatePicture: user.certificatePicture || null,
          skills: skillsData,
          email: user.email || "",
          id: user.id || "",
        }));
      } else {
        console.error("No additional info found for user.");
      }
    } catch (error) {
      console.error("Error fetching additional info:", error);
    }
  };

  useEffect(() => {
    fetchAdditionalInfoData();
  }, []);

  const handleSuccess = () => {};

  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <form
              onSubmit={handleCompleteProfileSetup}
            >
              {jobId == 0 ? (
                <h2>Update Profile Information</h2>
              ) : (
                <h2>Complete Your Profile to Continue</h2>
              )}

              <Autocomplete
                multiple
                options={skillChoice}
                value={profileData.skills}
                onChange={(e, newSkills) =>
                  handleProfileDataChange("skills", newSkills)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Skills"
                    fullWidth
                    style={{ marginBottom: "20px" }}
                  />
                )}
              />

              <TextField
                label="Availability"
                select
                value={profileData.availability}
                onChange={(e) =>
                  handleProfileDataChange("availability", e.target.value)
                }
                error={errors.availability}
                helperText={errors.availability && "This field is required"}
                fullWidth
                required
                style={{ marginBottom: "20px" }}
              >
                <MenuItem value="full time">Full Time</MenuItem>
                <MenuItem value="part time">Part Time</MenuItem>
                <MenuItem value="temporary">Temporary</MenuItem>
                <MenuItem value="on call">On Call</MenuItem>
              </TextField>

              <TextField
                label="Years of Experience"
                type="number"
                required
                value={profileData.yearsOfExperience}
                onChange={(e) =>
                  handleProfileDataChange("yearsOfExperience", e.target.value)
                }
                error={errors.yearsOfExperience}
                helperText={
                  errors.yearsOfExperience && "This field is required"
                }
                fullWidth
                style={{ marginBottom: "20px" }}
              />

              <TextField
                label="A short Description about you"
                value={profileData.description}
                onChange={(e) =>
                  handleProfileDataChange("description", e.target.value)
                }
                error={errors.description}
                helperText={errors.description && "This field is required"}
                fullWidth
                multiline
                rows={4}
                style={{ marginBottom: "20px" }}
                required
              />

              <Autocomplete
                multiple
                options={[
                  "Infants (0-12 Months)",
                  "Toddlers",
                  "Pre-school (3-5 years)",
                  "School-Aged (5-12 years)",
                  "Teens (13+ years)",
                ]}
                value={profileData.preferredAgeGroups}
                onChange={(e, newAgeGroups) =>
                  handleProfileDataChange("preferredAgeGroups", newAgeGroups)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Preferred Age Groups"
                    fullWidth
                    style={{ marginBottom: "20px" }}
                  />
                )}
              />

              <TextField
                label="Languages Spoken"
                value={profileData.languagesSpoken}
                onChange={(e) =>
                  handleProfileDataChange("languagesSpoken", e.target.value)
                }
                error={errors.languagesSpoken}
                helperText={errors.languagesSpoken && "This field is required"}
                fullWidth
                required
                style={{ marginBottom: "20px" }}
              />

              <TextField
                label="Current Employment Status"
                select
                value={profileData.currentEmploymentStatus}
                onChange={(e) =>
                  handleProfileDataChange(
                    "currentEmploymentStatus",
                    e.target.value
                  )
                }
                error={errors.currentEmploymentStatus}
                helperText={
                  errors.currentEmploymentStatus && "This field is required"
                }
                fullWidth
                required
                style={{ marginBottom: "20px" }}
              >
                <MenuItem value="employed">Employed</MenuItem>
                <MenuItem value="not employed">Not Employed</MenuItem>
              </TextField>

              <h3>Next of Kin</h3>
              {nextOfKin.map((kin, index) => (
                <div key={index}>
                  <TextField
                    label={`Kin ${index + 1} Full Name`}
                    value={kin.fullName}
                    onChange={(e) =>
                      handleNextOfKinChange(index, "fullName", e.target.value)
                    }
                    type="tel"
                    required
                    fullWidth
                    style={{ marginTop: 10, marginBottom: 10 }}
                  />
                  <div className="roleSelection">
                    <select
                      className="role-dropdown"
                      value={kin.relationShip}
                      onChange={(e) =>
                        handleNextOfKinChange(
                          index,
                          "relationShip",
                          e.target.value
                        )
                      }
                      required
                      style={{
                        width: "100%",
                        height: "50px",
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <option value="">RelationShip.</option>
                      <option value="Brother">Brother</option>
                      <option value="Sister">Sister</option>
                      <option value="Cousin">Cousin</option>
                      <option value="Mother">Mother</option>
                      <option value="Father">Father</option>
                      <option value="Auntie">Auntie</option>
                      <option value="Uncle">Uncle</option>
                      <option value="Guardian">Guardian</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <TextField
                    label={`Kin ${index + 1} Phone Number`}
                    value={kin.phoneNumber}
                    onChange={(e) =>
                      handleNextOfKinChange(
                        index,
                        "phoneNumber",
                        e.target.value
                      )
                    }
                    error={errors[`kin${index + 1}PhoneNumber`]}
                    helperText={
                      errors[`kin${index + 1}PhoneNumber`] &&
                      "This field is required"
                    }
                    type="tel"
                    fullWidth
                    required
                    style={{ marginTop: 10, marginBottom: 10 }}
                  />
                  <TextField
                    label={`Kin ${index + 1} Email`}
                    value={kin.email}
                    onChange={(e) =>
                      handleNextOfKinChange(index, "email", e.target.value)
                    }
                    fullWidth
                  />
                </div>
              ))}
              <h3>Referees</h3>
              {referees.map((referee, index) => (
                <div key={index}>
                  <TextField
                    label={`Referee ${index + 1} Full Name`}
                    value={referee.fullName}
                    type="tel"
                    onChange={(e) =>
                      handleRefereesChange(index, "fullName", e.target.value)
                    }
                    required
                    fullWidth
                    style={{ marginTop: 10, marginBottom: 10 }}
                  />
                  <TextField
                    label={`Referee ${index + 1} Phone Number`}
                    value={referee.phoneNumber}
                    type="tel"
                    onChange={(e) =>
                      handleRefereesChange(index, "phoneNumber", e.target.value)
                    }
                    required
                    fullWidth
                    style={{ marginTop: 10, marginBottom: 10 }}
                  />
                </div>
              ))}
              <TextField
                label="Do you have any criminal record?"
                select
                value={profileData.criminalRecord}
                onChange={(e) =>
                  handleProfileDataChange("criminalRecord", e.target.value)
                }
                error={errors.criminalRecord}
                helperText={errors.criminalRecord && "This field is required"}
                fullWidth
                required
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                <MenuItem value="yes">Yes</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </TextField>

              {profileData.criminalRecord === "yes" && (
                <TextField
                  label="If yes, please explain"
                  value={profileData.criminalRecordExplanation}
                  onChange={(e) =>
                    handleProfileDataChange(
                      "criminalRecordExplanation",
                      e.target.value
                    )
                  }
                  error={errors.criminalRecordExplanation}
                  helperText={
                    errors.criminalRecordExplanation && "This field is required"
                  }
                  fullWidth
                  multiline
                  rows={4}
                  style={{ marginBottom: "20px" }}
                />
              )}

              <p style={{ display: "flex" }}>
                <label htmlFor="idPicture" style={{ marginRight: 10 }}>
                  Upload Front ID Picture
                </label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    handleProfileDataChange("idPicture", e.target.files[0])
                  }
                />
              </p>
              <p style={{ display: "flex" }}>
                <label htmlFor="idPicture" style={{ marginRight: 10 }}>
                  Upload Back ID Picture
                </label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    handleProfileDataChange("idBackPicture", e.target.files[0])
                  }
                />
              </p>

              <p style={{ display: "flex" }}>
                <label style={{ marginRight: 10 }}>Upload Certificate</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) =>
                    handleProfileDataChange(
                      "certificatePicture",
                      e.target.files[0]
                    )
                  }
                />
              </p>
              {jobId == 0 ? (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "20px" }}
                >
                  Update Profile Information
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "20px" }}
                >
                  Save Profile and Continue
                </Button>
              )}
            </form>
          </div>
        </div>
      </div>

      <JobApplicationModalConfirmation
        isOpen={displayTheJobConfirmationModal}
        onClose={handleCloseJobConfirmationModal}
        jobId={jobId}
        onSuccess={handleSuccess}
      />
    </div>
  );
}

export default CompleteProfile;
