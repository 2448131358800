export const homeOwner = "home-owner";
export const Gardener = "gardener";
export const Chef = "chef";
export const houseHelp = "house-help";
export const adminEmail = "nanniesdesk@gmail.com";

export const locations = {
  kenyan_counties: [
    "Mombasa",
    "Kwale",
    "Kilifi",
    "Tana River",
    "Lamu",
    "Taita Taveta",
    "Garissa",
    "Wajir",
    "Mandera",
    "Marsabit",
    "Isiolo",
    "Meru",
    "Tharaka Nithi",
    "Embu",
    "Kitui",
    "Machakos",
    "Makueni",
    "Nyandarua",
    "Nyeri",
    "Kirinyaga",
    "Murang'a",
    "Kiambu",
    "Turkana",
    "West Pokot",
    "Samburu",
    "Trans Nzoia",
    "Uasin Gishu",
    "Elgeyo Marakwet",
    "Nandi",
    "Baringo",
    "Laikipia",
    "Nakuru",
    "Narok",
    "Kajiado",
    "Kericho",
    "Bomet",
    "Kakamega",
    "Vihiga",
    "Bungoma",
    "Busia",
    "Siaya",
    "Kisumu",
    "Homa Bay",
    "Migori",
    "Kisii",
    "Nyamira",
    "Nairobi",
  ],
};

export const tribes = {
  kenyan_tribes: [
    "Kikuyu",
    "Luo",
    "Luhya",
    "Kalenjin",
    "Kamba",
    "Kisii",
    "Meru",
    "Embu",
    "Mijikenda",
    "Maasai",
    "Turkana",
    "Somali",
    "Teso",
    "Samburu",
    "Pokot",
    "Boran",
    "Gabra",
    "Orma",
    "Rendille",
    "Tharaka",
    "Chonyi",
    "Kauma",
    "Giriama",
    "Jibana",
    "Duruma",
    "Digo",
    "Rabai",
    "Taita",
    "Taveta",
    "Ameru",
    "Aweer",
    "Boni",
    "Malakote",
    "Nubi",
    "Suba",
    "Ogiek",
    "El Molo",
    "Sakuye",
    "Bajuni",
    "Kurya",
    "Dasnach",
    "Ilchamus",
    "Kore",
    "Asian",
    "Njemps",
    "Sabaot",
    "Tachoni",
    "Waata",
  ],
};

export const userSkills = {
  nanny_skills: [
    "Newborn care",
    "Baby and toddler care",
    "Cooking",
    "Elderly caregiving",
    "First aid and CPR certification",
    "Housekeeping",
    "Dish cleaning",
    "Laundry and ironing",
    "House cleaning and organization",
    "Child safety knowledge",
    "Homework assistance",
    "Driving skills",
    "Availability for travel",
    "Time management",
    "Pet care",
    "Cultural sensitivity and multilingual abilities",
  ],
  gardener_skills: [
    "Planting and cultivating (flowers, vegetables, trees, etc.)",
    "Landscape design",
    "Pruning and weeding",
    "Soil testing and fertilization",
    "Pest and disease management",
    "Knowledge of seasonal plants and crops",
    "Maintenance of gardening equipment",
  ],
  chef_skills: [
    "Menu planning and meal preparation",
    "Cooking techniques (baking, grilling, roasting, etc.)",
    "Food presentation and plating",
    "Knowledge of dietary restrictions and nutrition",
    "Inventory and kitchen management",
    "Food safety and hygiene",
    "Creating and developing new recipes",
    "Cooking for large groups or events",
    "Garnishing and decoration",
    "Time management in food preparation",
    "Experience with various cuisines",
    "Teamwork and collaboration in the kitchen",
  ],
};

export const countryCodesList = [
  { code: "+254", label: "Kenya" },
  // { code: "+44", label: "United Kingdom" },
  // { code: "+91", label: "India" },
  // { code: "+61", label: "Australia" },
  // { code: "+81", label: "Japan" },
  // Add more countries as needed
];