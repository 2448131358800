import React, { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { getLocalStorageVariable, postFileDataToAPI } from "../Shared/shared";

const BlogPostForm = ({ postDataToAPI }) => {
  const [title, setTitle] = useState("");
  const [post, setPost] = useState("");
  const userEmail = getLocalStorageVariable("userEmail");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("post", post);
    formData.append("email", userEmail);

    try {
      const response = await postFileDataToAPI(
        "/blog/addBlogPost.php",
        formData
      );
      if (response === "Success") {
        Swal.fire("Success", "Post added successfully!", "success");
        setTitle("");
        setPost("");
      } else {
        Swal.fire("Error", "Failed to add post.", "error");
      }
    } catch (error) {
      Swal.fire("Error", "Something went wrong. Please try again.", "error");
    }
  };

  return (
    <div className={"dashboard-container"}>
      <div className="employer-dashboard-page">
        <form onSubmit={handleSubmit}>
          <div>
            <Link to="/seePosts" style={{ marginRight: 20 }}>
              SeePosts
            </Link>
            <Link to="/adminDashboard">Back</Link>
          </div>
          <div className="add-blog-section">
            <input
              type="text"
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
            <textarea
              placeholder="Post content"
              value={post}
              onChange={(e) => setPost(e.target.value)}
              required
            ></textarea>
            <button type="submit">Add Post</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BlogPostForm;
