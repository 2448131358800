import React from "react";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai"; // Import icons
import "../CSS/ViewConnectModal.css";
import { useNavigate } from "react-router-dom";

const ViewConnectModal = ({ isOpen, onClose, connectEmail, connectPhoneNumber }) => {
  const navigate = useNavigate();

  if (!isOpen) return null;

  const redirectToProfileComplete = (jobId) => {
    navigate("/completeProfile", { state: { jobId } });
  };

  return (
    <div
      className="viewConnectLays"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 1000,
      }}
    >
      <div
        className="viewConnectContents"
        style={{
          width: "90%",
          maxWidth: "400px",
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
        }}
      >
        <h3 style={{ marginBottom: "20px", fontSize: "20px" }}>Connect with this contact information</h3>
        <p style={{ marginBottom: "10px" }}>
          <strong>Email:</strong> {connectEmail}
        </p>
        <p style={{ marginBottom: "20px" }}>
          <strong>Phone Number:</strong> {connectPhoneNumber}
        </p>
        <div style={{ display: "flex", flexDirection: "column", gap: "15px", alignItems: "center" }}>
          {/* Button to compose an email */}
          <button
            className="actionBTN"
            style={{
              width: "100%",
              maxWidth: "300px",
              padding: "10px",
              backgroundColor: "#001748",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              fontSize: "16px",
            }}
            onClick={() => (window.location.href = `mailto:${connectEmail}`)}
          >
            <AiOutlineMail size={20} /> Send Email
          </button>

          {/* Button to initiate a call */}
          <button
            className="actionBTN"
            style={{
              width: "100%",
              maxWidth: "300px",
              padding: "10px",
              backgroundColor: "#642D92",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              fontSize: "16px",
            }}
            onClick={() => (window.location.href = `tel:${connectPhoneNumber}`)}
          >
            <AiOutlinePhone size={20} /> Call
          </button>
        </div>
        <button
          className="closeBTN"
          style={{
            marginTop: "20px",
            padding: "10px",
            backgroundColor: "#673AB7",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "16px",
            width: "100%",
            maxWidth: "300px",
          }}
          onClick={() => redirectToProfileComplete(0)}
        >
          Complete/Edit Profile
        </button>
        {/* Close Button */}
        <button
          className="closeBTN"
          style={{
            marginTop: "20px",
            padding: "10px",
            backgroundColor: "#DC3545",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "16px",
            width: "100%",
            maxWidth: "300px",
          }}
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ViewConnectModal;
