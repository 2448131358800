import React from "react";
import "../CSS/terms.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Terms = () => {
  const navigate = useNavigate();

  return (
    <div className="terms-container">
      <div className="terms-header">
        <h3>Terms and Conditions of Use for Nannies Desk App</h3>
        <p>Effective Date: [1/12/2024]</p>
      </div>

      <div className="terms-content">
        <p>
          Welcome to the Nannies Desk App. By downloading, installing, or using
          the App, you agree to abide by these Terms and Conditions. If you do
          not agree to these Terms, please uninstall the App and discontinue its
          use immediately.
        </p>

        <h4>1. Introduction</h4>
        <p>
          Nannies Desk is a digital platform connecting Nannies with Employers.
          While we verify Users' identification and provide Nannies with
          training, we do not take responsibility for their conduct, actions, or
          outcomes once employers engage Nannies.
        </p>

        <h4>2. Acceptance of Terms</h4>
        <p>
          By accessing or using the App, you confirm that you have read,
          understood, and agreed to these Terms. You also agree to comply with
          all applicable laws and regulations while using the App.
        </p>

        <h4>3. User Eligibility</h4>
        <p>
          <strong>3.1 Age Restriction:</strong> You must be 18 to create an
          account and use the App.
        </p>
        <p>
          <strong>3.2 Registration Requirements:</strong> Users are required to
          provide accurate and verifiable information, including a valid
          identification document, during registration.
        </p>
        <p>
          <strong>3.3 Verification:</strong> The App verifies identification
          documents during registration to promote a safe environment but does
          not guarantee Users' trustworthiness or suitability.
        </p>

        <h4>4. Services Provided</h4>
        <p>
          <strong>4.1:</strong> The App facilitates communication and
          connections between Employers and Nannies.
        </p>
        <p>
          <strong>4.2:</strong> Nannies Desk offers training programs to equip
          Nannies with essential skills for domestic work.
        </p>
        <p>
          <strong>4.3:</strong> The App does not mediate contracts, payments, or
          agreements between Employers and Nannies.
        </p>

        <h4>5. User Responsibilities</h4>
        <p>
          <strong>5.1 Employers:</strong>
        </p>
        <ul>
          <li>They must conduct their due diligence before hiring a nanny.</li>
          <li>
            Ensure fair treatment of Nannies and comply with applicable labor
            laws.
          </li>
        </ul>
        <p>
          <strong>5.2 Nannies:</strong>
        </p>
        <ul>
          <li>Provide truthful and accurate information in their profiles.</li>
          <li>Fulfill agreed job responsibilities with professionalism.</li>
        </ul>

        <h4>6. Disclaimer of Liability</h4>
        <p>
          <strong>6.1:</strong> Nannies Desk acts solely as a platform for
          facilitating connections and does not guarantee the conduct,
          suitability, or performance of either Nannies or Employers.
        </p>
        <ul>
          <li>
            Any disputes, losses, or damages arising from interactions between
            Nannies and Employers.
          </li>
          <li>
            Actions, behaviors, or misconduct of any User after engagement.
          </li>
        </ul>

        <h4>7. KYC Documentation</h4>
        <p>
          <strong>7.1:</strong> All registered Users must provide Know Your
          Customer (KYC) documentation, including valid ID and proof of
          residence.
        </p>
        <p>
          <strong>7.2:</strong> Verified KYC documentation will only be shared
          with relevant authorities or stakeholders when required by law.
        </p>

        <h4>8. Prohibited Activities</h4>
        <ul>
          <li>
            Providing false or misleading information during registration or
            profile creation.
          </li>
          <li>Using the App for fraudulent, illegal, or harmful purposes.</li>
          <li>Harassing, abusing, or discriminating against other Users.</li>
        </ul>

        <h4>9. Privacy and Data Protection</h4>
        <p>
          <strong>9.1:</strong> The App collects and processes User data in
          accordance with our Privacy Policy, which complies with applicable
          data protection laws.
        </p>
        <p>
          <strong>9.2:</strong> By using the App, you consent to the collection,
          storage, and use of your data as outlined in the Privacy Policy.
        </p>

        <h4>10. Dispute Resolution</h4>
        <p>
          <strong>10.1:</strong> Users are responsible for independently
          resolving any disputes arising from their engagement.
        </p>
        <p>
          <strong>10.2:</strong> Nannies Desk may provide relevant information,
          such as verified KYC documents, to assist in resolving disputes upon
          formal request.
        </p>

        <h4>11. Account Suspension and Termination</h4>
        <p>
          <strong>11.1:</strong> Nannies Desk reserves the right to suspend or
          terminate a User’s account for violations of these Terms or any
          activity that compromises the safety or integrity of the platform.
        </p>
        <p>
          <strong>11.2:</strong> Users may deactivate their accounts through the
          App at any time.
        </p>

        <h4>12. Updates to the Terms</h4>
        <p>
          Nannies Desk reserves the right to modify these Terms at any time.
          Updates will be communicated through the App, and continued use after
          changes are made constitutes acceptance of the revised Terms.
        </p>

        <h4>13. Governing Law</h4>
        <p>
          The laws of Kenya govern these Terms. Any disputes arising under these
          Terms shall be resolved exclusively in the courts.
        </p>

        <h4>14. Contact Information</h4>
        <p>For inquiries, feedback, or support, contact us:</p>
        <ul>
          <li>Email: info@nanniesdesk.com</li>
          <li>Phone: +254722525689</li>
        </ul>

        <p>
          By using the Nannies Desk App, you confirm that you have read,
          understood, and agreed to these Terms and Conditions.
        </p>
      </div>

      <div className="terms-header">
        <h3>Privacy Policy for Nannies Desk</h3>
        <p>Effective Date: [1/12/2024]</p>
      </div>

      <div className="terms-content">
        <p>
          At Nannies Desk, your privacy is important to us. This Privacy Policy
          outlines how we collect, use, and protect your personal information in
          compliance with the applicable laws of Kenya, including the Data
          Protection Act, 2019. By using our website, services, or mobile
          application, you agree to the practices outlined in this policy.
        </p>

        <h4>1. Information We Collect</h4>
        <p>
          <strong>1.1 Personal Information:</strong>
        </p>
        <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Mailing address</li>
          <li>
            Any other details necessary to provide or improve our services
          </li>
        </ul>
        <p>
          <strong>1.2 Non-Personal Information:</strong> We may collect
          non-personal data, such as browser type, IP address, and information
          about how you interact with our website or app, to help us optimize
          the user experience.
        </p>

        <h4>2. When We Collect Information</h4>
        <ul>
          <li>When you register or create an account with us</li>
          <li>When you fill out a contact form or sign up for a newsletter</li>
          <li>When you use our services or browse our website</li>
          <li>When you contact us for support or inquiries</li>
        </ul>

        <h4>3. How We Use Your Information</h4>
        <ul>
          <li>To personalize and improve your experience with our services</li>
          <li>To provide better customer support</li>
          <li>To process transactions and service requests efficiently</li>
          <li>
            To send periodic emails or notifications about updates, offers, and
            other relevant content
          </li>
          <li>
            To enhance the security and performance of our website and services
          </li>
        </ul>

        <h4>4. How We Protect Your Information</h4>
        <ul>
          <li>
            Use Secure Socket Layer (SSL) technology to encrypt sensitive data
          </li>
          <li>
            Limit access to personal information to authorized personnel who are
            required to maintain its confidentiality
          </li>
          <li>
            Regularly scan our website for vulnerabilities and use advanced
            security measures to protect your data
          </li>
          <li>
            All payment transactions are processed through a third-party gateway
            provider and are not stored or processed on our servers
          </li>
        </ul>

        <h4>5. Use of Cookies</h4>
        <p>
          Cookies are small files that help us improve your experience by
          tracking your activity on our site. We use cookies to:
        </p>
        <ul>
          <li>Save your preferences for future visits</li>
          <li>
            Analyze site traffic and user interaction to enhance our offerings
          </li>
        </ul>
        <p>
          You can choose to disable cookies through your browser settings.
          However, doing so may limit certain functionalities of the website.
        </p>

        <h4>6. Sharing Your Information</h4>
        <p>
          We do not sell, trade, or transfer your personally identifiable
          information to third parties unless necessary to:
        </p>
        <ul>
          <li>Comply with legal obligations</li>
          <li>
            Provide services through trusted partners who adhere to
            confidentiality agreements
          </li>
        </ul>
        <p>
          Non-personal data may be shared for analytics, advertising, or other
          legitimate uses.
        </p>

        <h4>7. Third-Party Links</h4>
        <p>
          Our website may include links to third-party websites or services.
          These external sites have their own privacy policies, and we are not
          responsible for their content or practices. We encourage you to review
          their policies before interacting with them.
        </p>

        <h4>8. Kenya’s Data Protection Act Compliance</h4>
        <ul>
          <li>
            You have the right to access, correct, or delete your personal data
          </li>
          <li>
            We will notify users of any changes to this privacy policy via email
          </li>
          <li>Our privacy policy is easily accessible on our website</li>
        </ul>

        <h4>9. Do Not Track Signals</h4>
        <p>
          We honor Do Not Track (DNT) signals and do not engage in any form of
          behavioral tracking when DNT is enabled in your browser settings.
        </p>

        <h4>10. Third-Party Behavioral Tracking</h4>
        <p>
          We do not allow third-party behavioral tracking on our website or app.
        </p>

        <h4>11. Data Breach Response</h4>
        <ul>
          <li>Notify affected users via email within 7 business days</li>
          <li>
            Take immediate steps to mitigate the breach and prevent future
            occurrences
          </li>
        </ul>

        <h4>12. Your Rights</h4>
        <ul>
          <li>Request access to your personal data</li>
          <li>Request that we update or delete your personal data</li>
          <li>
            Complain to the Office of the Data Protection Commissioner (ODPC) if
            your rights under the Data Protection Act, 2019 have been violated
          </li>
        </ul>
        <p>
          To exercise these rights, please contact us at info@nanniesdesk.com.
        </p>

        <h4>13. Changes to This Privacy Policy</h4>
        <p>
          We reserve the right to update or modify this Privacy Policy at any
          time. Any changes will be communicated via email and updated on this
          page.
        </p>

        <h4>14. Contact Us</h4>
        <p>
          For inquiries about this Privacy Policy or how your data is handled,
          contact us:
        </p>
        <ul>
          <li>Email: info@nanniesdesk.com</li>
          <li>Phone: +254722525689</li>
        </ul>
        <p>
          By using our services, you consent to this Privacy Policy. Thank you
          for trusting Nannies Desk with your personal information.
        </p>
      </div>

      <div className="terms-header">
        <h3>Disclaimer </h3>
        <p>Effective Date: [1/12/2024]</p>
      </div>
      
      <div className="terms-content">
        <p>
          The information provided on this website has been compiled with the
          utmost care by Nannies Desk. However, the content on this website may
          contain inaccuracies, errors, or typographical mistakes. By accessing
          or using this website, you acknowledge and agree to the following
          terms:
        </p>

        <h4>1. General Disclaimer</h4>
        <p>
          Nannies Desk makes no guarantees, warranties, or representations,
          express or implied, about the completeness, accuracy, reliability, or
          availability of the information provided on this website. We are not
          responsible or liable for any loss or damage arising from reliance on
          the information provided on this website.
        </p>
        <p>
          We reserve the right to make changes, corrections, or improvements to
          the content on this website at any time without prior notice.
        </p>

        <h4>2. Limitation of Liability</h4>
        <ul>
          <li>
            Nannies Desk shall not be held liable for any direct, indirect,
            incidental, consequential, or punitive damages arising from access
            to or use of this website.
          </li>
          <li>
            Losses caused by incorrect, incomplete, or outdated information on
            the website.
          </li>
          <li>
            Losses arising from the inability to use this website, delays, or
            interruptions, whether caused by technical faults or other
            circumstances beyond our control.
          </li>
        </ul>
        <p>
          Nannies Desk also disclaims liability for any loss resulting from the
          use of electronic communication, including but not limited to delayed
          transmission, non-delivery of messages, interception by unauthorized
          parties, or the presence of viruses.
        </p>

        <h4>3. Third-Party Claims and Indemnity</h4>
        <p>
          By using this website, you agree to indemnify and hold Nannies Desk
          harmless from any claims, losses, or damages brought by third parties
          as a result of:
        </p>
        <ul>
          <li>
            Your violation of the terms and conditions of using this website.
          </li>
          <li>Unauthorized or improper use of the website.</li>
          <li>
            Breach of any law, regulation, or third-party rights while using
            this website.
          </li>
        </ul>

        <h4>4. External Links</h4>
        <p>
          This website may contain links to third-party websites. Nannies Desk
          is not responsible for the content, policies, or practices of these
          external websites and does not endorse or make representations about
          them. Use of these third-party websites is at your own risk.
        </p>

        <h4>5. Use of Website and Governing Law</h4>
        <p>
          By accessing and using this website, you agree to use it in compliance
          with applicable Kenyan laws, including but not limited to the Kenya
          Data Protection Act, 2019, Consumer Protection Act, 2012, and other
          relevant regulations.
        </p>
        <p>
          This disclaimer and any disputes arising out of your use of the
          website shall be governed by and construed in accordance with the laws
          of Kenya. Any legal proceedings shall be subject to the jurisdiction
          of the courts of Kenya.
        </p>

        <h4>6. No Guarantee of Continuous Access</h4>
        <p>
          While we strive to ensure the website is accessible at all times,
          Nannies Desk does not guarantee uninterrupted or error-free access. We
          reserve the right to suspend, withdraw, or modify the services
          provided through this website without prior notice.
        </p>

        <h4>7. Updates to Disclaimer</h4>
        <p>
          Nannies Desk reserves the right to update, revise, or modify this
          disclaimer at any time without prior notice. It is your responsibility
          to regularly review this page for any changes. Continued use of the
          website constitutes acceptance of the revised disclaimer.
        </p>

        <h4>Contact Information</h4>
        <p>For further inquiries or concerns, please contact us:</p>
        <ul>
          <li>Email: info@nanniesdesk.com</li>
          <li>Phone: +254722525689</li>
        </ul>

        <p>
          By using the Nannies Desk website, you confirm that you understand and
          agree to the terms of this disclaimer.
        </p>
      </div>

      <div className="terms-footer">
        <Button
          style={{
            backgroundColor: "#DE1877",
            color: "white",
            borderRadius: "40px",
            padding: "8px 32px",
          }}
          variant="contained"
          onClick={() => navigate("/register")}
        >
          Back
        </Button>
      </div>
    </div>
  );
};

export default Terms;
