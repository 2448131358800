import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getLocalStorageVariable, postDataToAPI } from "../Shared/shared";

function ViewFullJobDetails() {
  const navigate = useNavigate();
  const [jobDetails, setJobDetails] = useState(null);
  const location = useLocation();
  const data = location.state || {};
  const jobId = data.jobId || "";

  const userEmail = getLocalStorageVariable("userEmail");
  const role = getLocalStorageVariable("role");
  // Fetch Full Job Details
  useEffect(() => {
    const fetchJobDetails = async () => {
      if (!jobId) return; // Ensure jobId is valid

      const formData = new FormData();
      formData.append("jobId", jobId);
      try {
        const response = await postDataToAPI(
          "/dashboard/fetchJobDetailsById.php",
          formData
        );

        if (response) {
          setJobDetails(response[0]);
        }
      } catch (error) {
        console.error("Error fetching job details:", error);
      }
    };

    fetchJobDetails();
  }, [jobId]);

  if (!jobDetails) {
    // Render a loading message until the job details are fetched
    return (
      <div
        style={{
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "8px",
          maxWidth: "100%",
          margin: "50px auto",
          textAlign: "center",
        }}
      >
        <h3 style={{ marginBottom: "20px" }}>Loading Job Details...</h3>
      </div>
    );
  }

  const handlViewEmployerProfile = () => {
    const email = jobDetails.email;
    const sessionType = role;

    navigate("/ViewProfile", { state: { email, sessionType } });
  };

  return (
    <div className={"dashboard-container"}>
      <div
        className={"employer-dashboard-page"}
        style={{
          // backgroundColor: "#fff",
          // padding: "20px",
          // borderRadius: "8px",
          // maxWidth: "100%",
          // margin: "50px auto",
          // textAlign: "center",
        }}
      >
        <div
          style={{ textAlign: "left", marginBottom: "20px" }}
        >
          <div className="col-sm-12">
            <p className="mb-2">
              <strong>Role:</strong> {jobDetails.role}
            </p>
            <p className="mb-2">
              <strong>Description:</strong> {jobDetails.description}
            </p>
            <p className="mb-2">
              <strong>Location:</strong> {jobDetails.jobLocation}
            </p>
            <p className="mb-2">
              <strong>Pay:</strong> Ksh {jobDetails.pay}
            </p>
            <p className="mb-2">
              <strong>Minimum Qualifications:</strong> {jobDetails.minimumQualifications}
            </p>
            <p className="mb-2">
              <strong>Years Of Experience:</strong> {jobDetails.yearsOfExperience}
            </p>
            <p className="mb-2">
              <strong>Employment Type:</strong> {jobDetails.employmentType}
            </p>
            <p className="mb-2">
              <strong>Number of Days:</strong> {jobDetails.numberOfDays}
            </p>
            <p className="mb-2">
              <strong>Skills Needed:</strong>{" "}
              {JSON.parse(jobDetails.skills).join(", ")}
            </p>
            <p className="mb-2">
              <strong>Status:</strong> {jobDetails.status}
            </p>
            <p className="mb-2">
              <strong>Posted On:</strong> {jobDetails.timeStamp}
            </p>
            <div>
              <button onClick={handlViewEmployerProfile} className="btn btn-primary">
                View Employer Profile
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewFullJobDetails;
