import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  TextField,
} from "@mui/material";
import Swal from "sweetalert2";
import { postDataToAPI, getLocalStorageVariable } from "../Shared/shared";
import { Link } from "react-router-dom";

const UserDataGrid = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filterText, setFilterText] = useState("");
  const userEmail = getLocalStorageVariable("userEmail");
  const [errorMessage, setErrorMessage] = useState("");

  const fetchAllUsers = async () => {
    try {
      const response = await postDataToAPI("/dashboard/fetchAllUsers.php", {});
      setUsers(response || []);
      setFilteredUsers(response || []); // Initialize filtered users
    } catch (error) {
      setErrorMessage("Error fetching users. Please try again later.");
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  // Handle user status change
  const handleUserStatusChange = async (id, status, email) => {
    const newStatus = status === "Active" ? "Inactive" : "Active";
    const result = await Swal.fire({
      title: `Are you sure you want to ${
        newStatus === "Active" ? "activate" : "deactivate"
      } this user?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, update it!",
      cancelButtonText: "No, cancel!",
    });

    if (result.isConfirmed) {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("status", newStatus);

      const response = await postDataToAPI("/dashboard/updateUserStatus.php", formData);

      const formDataa = new FormData();
      if (newStatus === "Active") {
        formDataa.append("action", "activateUser");
      } else if (newStatus === "Inactive") {
        formDataa.append("action", "deactivateUser");
      }
      formDataa.append("email", email);
      const responsee = await postDataToAPI("/Emails/sendEmails.php", formDataa);

      if (response === "Success") {
        if (responsee === "Email Sent") {
          fetchAllUsers();
          Swal.fire({
            title: "Success!",
            text: `User has been ${
              newStatus === "Active" ? "activated" : "deactivated"
            } successfully.`,
            icon: "success",
            confirmButtonText: "OK",
          });
        }
      } else {
        Swal.fire({
          title: "Error",
          text: "Failed to update user status.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  // Handle filter change
  const handleFilterChange = (event) => {
    const value = event.target.value.toLowerCase();
    setFilterText(value);

    const filtered = users.filter(
      (user) =>
        user.fullName.toLowerCase().includes(value) ||
        user.email.toLowerCase().includes(value) ||
        user.status.toLowerCase().includes(value)
    );
    setFilteredUsers(filtered);
  };

  return (
    <>
      <div style={{ marginTop: "0px", padding: "20px" }}>
        <h2>Users</h2>
        <Link to="/adminDashboard">Back</Link>

        <TextField
          type="tel"
          label="Filter users"
          variant="outlined"
          fullWidth
          margin="normal"
          value={filterText}
          onChange={handleFilterChange}
        />

        <TableContainer component={Paper} style={{ marginTop: "20px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Full Name</TableCell>
                <TableCell align="center">Age</TableCell>
                <TableCell align="center">Phone Number</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Registration Type</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.map((user) => (
                <TableRow key={user.id}>
                  <TableCell align="center">{user.fullName}</TableCell>
                  <TableCell align="center">{user.age}</TableCell>
                  <TableCell align="center">{user.phoneNumber}</TableCell>
                  <TableCell align="center">{user.email}</TableCell>
                  <TableCell align="center">{user.registrationType}</TableCell>
                  <TableCell align="center">{user.status}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color={user.status === "Active" ? "secondary" : "primary"}
                      onClick={() =>
                        handleUserStatusChange(user.id, user.status, user.email)
                      }
                    >
                      {user.status === "Active" ? "Deactivate" : "Activate"}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default UserDataGrid;
