import axios from "axios";
import { server } from "../configs/configs";
import Swal from "sweetalert2";

export const postDataToAPI = async (endpoint, formData, retryCount = 3) => {
  try {
    const response = await axios.post(server + endpoint, formData);

    if (response.status === 200) {
      const data = response.data;
      return data;
    } else {
      throw new Error("Network response was not OK");
    }
  } catch (error) {
    if (error.response && error.response.status === 403 && retryCount > 0) {
      console.log("Forbidden error, retrying request...");
      return postDataToAPI(endpoint, formData, retryCount - 1);
    } else {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
};

export const postFileDataToAPI = async (endpoint, formData, retryCount = 3) => {
  try {
    const response = await axios.post(server + endpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data", // Optional, but typically not needed
      },
    });

    if (response.status === 200) {
      const data = response.data;
      return data; // Return the data directly
    } else {
      throw new Error("Network response was not OK");
    }
  } catch (error) {
    if (error.response && error.response.status === 403 && retryCount > 0) {
      console.log("Forbidden error, retrying request...");
      return postDataToAPI(endpoint, formData, retryCount - 1);
    } else {
      console.error("Error fetching data:", error);
      throw error; // Rethrow the error to handle it where this function is called
    }
  }
};

// getUserLocation.js
export const getUserLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          resolve({ latitude, longitude });
        },
        (error) => {
          reject(error);
        }
      );
    } else {
      reject(new Error("Geolocation is not supported by this browser."));
    }
  });
};

export const handleGeolocationError = (error) => {
  if (error.code === error.PERMISSION_DENIED) {
    Swal.fire({
      title: "Location Permission Denied",
      text: "Please enable location services to continue.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Open Settings",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        window.open(
          "https://support.google.com/chrome/answer/9566474?hl=en",
          "_blank"
        );
      }
    });
  } else if (error.code === error.POSITION_UNAVAILABLE) {
    Swal.fire({
      title: "Location Error",
      text: "Unable to retrieve your location. Please check your network connection.",
      icon: "error",
      confirmButtonText: "OK",
    });
  } else {
    console.error("Geolocation error:", error.message);
  }
};

//SET LOCAL Storage Variable
export const setLocalStorageVariable = (name, value) => {
  localStorage.setItem(name, value);
};

export const getLocalStorageVariable = (name) => {
  const value = localStorage.getItem(name);
  return value;
};

export const calculateDistanceBetweenUsers = (lat1, long1, lat2, long2) => {
  const pi80 = Math.PI / 180;
  lat1 *= pi80;
  lat2 *= pi80;
  long1 *= pi80;
  long2 *= pi80;

  const r = 6372.797; // Average Earth radius in kilometers
  const dLat = lat2 - lat1;
  const dLon = long2 - long1;

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = r * c;
  return distance;
};

export const calculateMatch = async (secondEmail, regType, sessionType) => {
  const userSessionType = sessionType;
  const userEmail = getLocalStorageVariable("userEmail");

  const formData = new FormData();
  formData.append("email", userEmail);
  formData.append("Type", userSessionType);

  const data1 = await postDataToAPI("/fetchAdditionalInfoData.php", formData);
  // console.log("Data1: ", data1);

  const formDataa = new FormData();
  formDataa.append("email", secondEmail);
  formDataa.append("Type", regType);

  const data2 = await postDataToAPI("/fetchAdditionalInfoData.php", formDataa);
  // console.log("Data2: ", data2);

  // If either data1 or data2 is null, undefined, or an empty object/array, return 0
  if (
    !data1 ||
    !data2 ||
    (Array.isArray(data1) && data1.length === 0) ||
    (Array.isArray(data2) && data2.length === 0) ||
    (typeof data1 === "object" && Object.keys(data1).length === 0) ||
    (typeof data2 === "object" && Object.keys(data2).length === 0)
  ) {
    return 10;
  }
  const weights = {
    availability: 20,
    languagesSpoken: 20,
    servicesProvided: 20,
    yearsExperience: 20,
    familySize: 20,
  };

  let totalScore = 0;
  let maxScore = 0;

  if (
    data1?.availability &&
    data2?.workSchedule &&
    data1.availability === data2.workSchedule
  ) {
    totalScore += weights.availability;
  }
  maxScore += weights.availability;

  if (data1?.languagesSpoken && data2?.preferredLanguages) {
    const languagesSpokenArray = data1.languagesSpoken.split(",");
    const preferredLanguagesValues = Object.values(
      JSON.parse(data2.preferredLanguages)
    );

    const languagesMatch = languagesSpokenArray.some((lang) =>
      preferredLanguagesValues.includes(lang)
    );
    if (languagesMatch) {
      totalScore += weights.languagesSpoken;
    }
  }
  maxScore += weights.languagesSpoken;

  if (data1?.servicesProvided && data2?.preferredSkills) {
    const servicesProvidedArray = data1.servicesProvided.split(",");
    const servicesMatch = servicesProvidedArray.some((service) =>
      data2.preferredSkills.includes(service)
    );
    if (servicesMatch) {
      totalScore += weights.servicesProvided;
    }
  }
  maxScore += weights.servicesProvided;

  const experience1 = Number(data1?.yearsExperience) || 0;
  const experience2 = Number(data2?.experienceLevel || 0);
  const experienceDiff = Math.abs(experience1 - experience2);
  if (experienceDiff <= 2) {
    totalScore += weights.yearsExperience;
  }
  maxScore += weights.yearsExperience;

  if (data2?.familySize === data1?.familySize) {
    totalScore += weights.familySize;
  }
  maxScore += weights.familySize;

  // Ensure maxScore is greater than 0 before calculating the percentage
  return maxScore === 0 ? 0 : Math.round((totalScore / maxScore) * 100);
};

// Helper function to check for camera permissions
export const checkCameraPermissions = async () => {
  try {
    // Check if mediaDevices are available
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      throw new Error("Media devices not available");
    }

    // Get all media devices
    const devices = await navigator.mediaDevices.enumerateDevices();

    // Check if there is a video input device (camera)
    const hasCamera = devices.some((device) => device.kind === "videoinput");
    if (!hasCamera) {
      throw new Error("No camera found");
    }

    // If we are here, it means a camera exists. Now, check if permissions are granted.
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    const tracks = stream.getVideoTracks();
    const isGranted = tracks.length > 0; // If we get video tracks, permission was granted
    stream.getTracks().forEach((track) => track.stop()); // Stop the stream after check
    return isGranted;
  } catch (error) {
    return false;
  }
};

export const safeJsonParse = (data) => {
  try {
    return JSON.parse(data.replace(/^"|"$/g, ""));
  } catch (error) {
    console.error("Error parsing JSON:", error);
    return null;
  }
};
