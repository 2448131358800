import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { getLocalStorageVariable, postDataToAPI } from "../Shared/shared";

const BlogPostList = () => {
  const [posts, setPosts] = useState([]);
  const [editingPost, setEditingPost] = useState(null);
  const userEmail = getLocalStorageVariable("userEmail");

  // Fetch posts from the API
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await postDataToAPI("/blog/fetchBlogPosts.php", {});
        let data;

        try {
          // Try parsing if response is a string
          data = typeof response === "string" ? JSON.parse(response) : response;
        } catch (parseError) {
          console.error("Failed to parse response as JSON:", parseError);
          Swal.fire("Error", "Invalid server response.", "error");
          return;
        }

        setPosts(data);
      } catch (error) {
        console.error("Error fetching posts:", error);
        Swal.fire("Error", "Failed to fetch posts.", "error");
      }
    };

    fetchPosts();
  }, []);

  const handleDelete = async (id) => {
    // Show confirmation dialog
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this post? This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });

    // If user confirms, proceed with deletion
    if (result.isConfirmed) {
      const formData = new FormData();
      formData.append("id", id);

      try {
        const response = await postDataToAPI(
          "/blog/deleteBlogPost.php",
          formData
        );
        if (response === "Success") {
          setPosts(posts.filter((post) => post.id !== id));
          Swal.fire("Deleted!", "The post has been deleted.", "success");
        } else {
          Swal.fire("Error", "Failed to delete the post.", "error");
        }
      } catch (error) {
        Swal.fire("Error", "Something went wrong. Please try again.", "error");
      }
    }
  };

  // Handle editing a post
  const handleEdit = async (id, title, postContent) => {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("title", title);
    formData.append("post", postContent);

    try {
      const response = await postDataToAPI(
        "/blog/updateBlogPost.php",
        formData
      );
      if (response === "Success") {
        setPosts(
          posts.map((post) =>
            post.id === id ? { ...post, title, post: postContent } : post
          )
        );
        setEditingPost(null);
        Swal.fire("Success", "Post updated successfully!", "success");
      } else {
        Swal.fire("Error", "Failed to update post.", "error");
      }
    } catch (error) {
      Swal.fire("Error", "Something went wrong. Please try again.", "error");
    }
  };

  return (
    <div className={"employer-dashboard-page"}>
      <div style={{ marginTop: "0px" }}>
        {userEmail === "nanniesdesk@gmail.com" && (
          <Link to="/addBlogPost">Add Post</Link>
        )}
        {posts.map((post) => (
          <div
            key={post.id}
            style={{
              border: "1px solid black",
              margin: "10px",
              padding: "10px",
            }}
          >
            {editingPost === post.id ? (
              <div>
                <input
                  type="text"
                  defaultValue={post.title}
                  onChange={(e) => (post.title = e.target.value)}
                />
                <textarea
                  defaultValue={post.post}
                  onChange={(e) => (post.post = e.target.value)}
                ></textarea>
                <button
                  onClick={() => handleEdit(post.id, post.title, post.post)}
                >
                  Save
                </button>
                <button onClick={() => setEditingPost(null)}>Cancel</button>
              </div>
            ) : (
              <div>
                <h3>{post.title}</h3>
                <p>{post.post}</p>
                {userEmail === "nanniesdesk@gmail.com" && (
                  <>
                    <button onClick={() => setEditingPost(post.id)}>
                      Edit
                    </button>
                    <button onClick={() => handleDelete(post.id)}>
                      Delete
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogPostList;
