import React, { useState } from "react";
import { Modal, TextField } from "@mui/material";
import { postDataToAPI } from "../Shared/shared";
import { getLocalStorageVariable } from "./NavBar";
import Swal from "sweetalert2";

const ScheduleInterview = ({ isOpen, onClose, email, phoneNumber }) => {
  const [description, setDescription] = useState("");
  const userEmail = getLocalStorageVariable("userEmail");
  const [isDisabled, setIsDisabled] = useState(false);
  const [buttonText, setButtonText] = useState("Schedule Now");

  const handleClick = () => {
    // Disable the button and change text
    setIsDisabled(true);
    setButtonText("Scheduling...");

    // Re-enable button and reset text after 10 seconds
    setTimeout(() => {
      setIsDisabled(false);
      setButtonText("Schedule Now");
    }, 10000);
  };

  const scheduleNow = async () => {
    //disable button
    handleClick();
    const formDataa = new FormData();
    formDataa.append("from_email", userEmail);
    formDataa.append("email", email);
    formDataa.append("subject", "Interview Invitation");
    formDataa.append("status", "unread");
    formDataa.append("message", description);

    const res = await postDataToAPI(
      "/dashboard/addNotifications.php",
      formDataa
    );

    if (res === "Success") {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("message", description);
      const response = await postDataToAPI(
        "/Emails/sendScheduleEmails.php",
        formData
      );

      if (response === "Email Sent") {
        Swal.fire({
          title: "Success!",
          text: "Interview Scheduled Successfully",
          icon: "success",
        });

        //Send SMS to Potential employee
        const formData = new FormData();
        formData.append("from", userEmail);
        formData.append("recipients", phoneNumber);
        formData.append("message", description);

        console.log("From: ", userEmail);
        console.log("recipients: ", phoneNumber);
        console.log("message: ", description);

        const response = await postDataToAPI("/sms/sendSMS.php", formData);
        if (response) {
          console.log("Res: ", response);
        }

        setDescription("");
        onClose();
      } else {
        Swal.fire({
          title: "Failed!",
          text: "Failed to Schedule, try again Later",
          icon: "error",
        });
        onClose();
      }
    } else {
      Swal.fire({
        title: "Failed!",
        text: "Failed to Schedule, try again Later",
        icon: "error",
      });
      onClose();
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className="modals">
        <div>
          <h3>Schedule Interview</h3>
          <TextField
            label="Enter Meeting Details"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            multiline
            rows={4}
            style={{ marginBottom: "20px" }}
            required
          />

          <button
            style={{
              backgroundColor: isDisabled ? "#aaa" : "#DE1877", // Lighter color when disabled
              marginRight: 10
            }}
            disabled={isDisabled}
            onClick={scheduleNow}
          >
            {buttonText}
          </button>
          {/* <button
            style={{ backgroundColor: "#DE1777", marginRight: 10 }}
            onClick={scheduleNow}
          >
            Schedule Now
          </button> */}
          <button className="closeBTN" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ScheduleInterview;
