import React, { useState, useEffect } from "react";
import { Modal, TextField, Button } from "@mui/material";
import "../CSS/MpesaPaymentModal.css";
import { postDataToAPI } from "../Shared/shared";
import Swal from "sweetalert2";
import { getLocalStorageVariable } from "./NavBar";

const IdVerificationModal = ({
  isOpen,
  onClose,
  onIdVerificationSuccess,
  connectId,
}) => {
  const maxAttempts = 3; // Maximum allowed attempts
  const [idNumber, setIdNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isVerifyDisabled, setIsVerifyDisabled] = useState(false);
  const [remainingAttempts, setRemainingAttempts] = useState(maxAttempts);
  const [firstName, setFirstName] = useState();
  const [dbId, setDbId] = useState();

  useEffect(() => {
    // Load remaining attempts from localStorage
    const savedAttempts = localStorage.getItem("idVerificationAttempts");
    setRemainingAttempts(savedAttempts ? maxAttempts - parseInt(savedAttempts) : maxAttempts);
  }, []);

  const incrementAttempts = () => {
    const currentAttempts = parseInt(localStorage.getItem("idVerificationAttempts")) || 0;
    const newAttempts = currentAttempts + 1;
    localStorage.setItem("idVerificationAttempts", newAttempts);
    setRemainingAttempts(maxAttempts - newAttempts);
  };

  const handleIdNumberChange = (e) => {
    setIdNumber(e.target.value);
    if (errorMessage) setErrorMessage(""); // Clear error message on change
  };

  const handleVerifyId = async () => {
    if (remainingAttempts <= 0) {
      setErrorMessage("You have exceeded the maximum number of attempts.");
      return;
    }

    // Disable the button temporarily
    setIsVerifyDisabled(true);
    setTimeout(() => setIsVerifyDisabled(false), 10000);

    // Get Email
    const userEmail = getLocalStorageVariable("userEmail");

    // Fetch User Details From Db, Name and Id Number to be used in Verifications
    const formDataa = new FormData();
    formDataa.append("email", userEmail);

    const responsee = await postDataToAPI(
      "/dashboard/fetchProfileData.php",
      formDataa
    );
    if (responsee) {
      setFirstName(responsee.fullName.split(" ")[0]);
      setDbId(responsee.idNumber);
    }

    // Validate Id
    if (dbId !== idNumber) {
      incrementAttempts();
      setErrorMessage(
        `ID number rejected. You have ${remainingAttempts - 1} attempt(s) remaining.`
      );
      return;
    }

    const formData = new FormData();
    formData.append("userEmail", userEmail);
    formData.append("firstName", firstName);
    formData.append("idNumber", idNumber);
    formData.append("connectId", connectId);
    const response = await postDataToAPI("/IPRS/verifyIds.php", formData);

    if (response.success) {
      onIdVerificationSuccess();
      localStorage.removeItem("idVerificationAttempts"); // Clear attempts on success
    } else {
      incrementAttempts();
      setErrorMessage(
        `${response.error}. You have ${remainingAttempts - 1} attempt(s) remaining.`
      );
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={(e) => e.stopPropagation()}
      aria-labelledby="paymentModalsTitle"
    >
      <div className="modalsContents">
        <h2 id="payment-modals-title">Verify Your ID</h2>
        <p>You are required to verify your ID number to connect.</p>
        <TextField
          type="tel"
          label="Enter your ID Number"
          value={idNumber}
          onChange={handleIdNumberChange}
          fullWidth
          required
          disabled={remainingAttempts <= 0}
        />
        {/* Display error message */}
        {errorMessage && (
          <p className="error-text" style={{ color: "red", marginTop: "10px" }}>
            {errorMessage}
          </p>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleVerifyId}
          disabled={isVerifyDisabled || remainingAttempts <= 0}
          style={{ marginTop: "20px" }}
        >
          {remainingAttempts <= 0
            ? "Attempts Exceeded"
            : isVerifyDisabled
            ? "Please Wait..."
            : "Verify ID"}
        </Button>
        {/* Cancel Button */}
        <Button
          variant="outlined"
          onClick={onClose}
          style={{
            marginTop: "20px",
            marginLeft: "10px",
            backgroundColor: "black",
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default IdVerificationModal;
