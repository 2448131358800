import React from "react";

export const Header = (props) => {
  return (
    <div className="intro">
      <div className="overlay">
        <div className="overlay-content-container">
          <h1>
            Trusted Caregiver <br /> For Your Little<br /> Ones
          </h1>
          <p>We have so many satisfied parent who are really<br />happy to take our services</p>
          {/* <a
            href="#servicess"
            className="btn btn-custom btn-lg page-scroll"
          >
            Learn More
          </a> */}
        </div>
      </div>
    </div>
  );
};
