import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  getLocalStorageVariable,
  getUserLocation,
  handleGeolocationError,
  postDataToAPI,
  setLocalStorageVariable,
} from "../Shared/shared";
import Swal from "sweetalert2";
import '../CSS/Login.css';
import logo from '../dashboardTemplate/assets/brand/logo2.png';
import nannyImage from '../LandingPage/components/bg/nanny.jpeg';
import TextField from '@mui/material/TextField';
import { styled } from "@mui/material";

const StyledInputField = styled(TextField)({
  width: '100%',
  marginBottom: '32px',
  '& label': {
    fontFamily: 'inherit',
    color: '#6F7070'
  },
  '& label.Mui-focused': {
    color: '#DE1877 !important'
  },
  '& .MuiOutlinedInput-root': {
    fontFamily: 'inherit',
    borderRadius: '4px',
    '&.Mui-focused fieldset': {
      borderColor: '#642D92'
    }
  }
});

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const startSession = async (email) => {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("action", "newSession");
    await postDataToAPI("/sessions.php", formData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    const response = await postDataToAPI("/Auth/signIn.php", formData);

    if (response === "Success") {
      startSession(email);
      setLocalStorageVariable("userEmail", email);

      try {
        const { latitude, longitude } = await getUserLocation();
        const locationFormData = new FormData();
        locationFormData.append(
          "coordinates",
          JSON.stringify([latitude, longitude])
        );
        locationFormData.append("userEmail", email);
        await postDataToAPI("/Auth/updateUserLocation.php", locationFormData);
      } catch (error) {
        handleGeolocationError(error);
      }

      navigate("/dashboard");
      Swal.fire({
        title: "Success!",
        text: "Login successful! Redirecting to Dashboard",
        icon: "success",
        timer: 3000,
        showConfirmButton: false,
        willClose: () => {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      });

    } else {
      setErrorMessage(response);
    }
  };

  useEffect(() => {
    const userEmail = getLocalStorageVariable("userEmail");
    if (userEmail) navigate("/dashboard");
  }, [navigate]);

  return (
    <div className={"login-section-container"}>
      <div className={"login-left-container"}>
        <img src={logo} alt={"Logo"} className={"login-logo-image"} />
        <h3 className={"login-left-title"}>Trusted Caregiver For Your Little Ones</h3>
        <p className={"logo-description"}>We have so many satisfied parents who are really happy to take our services</p>
        <div className={"employer-details"}>
          <p className={"employer-details-text"}>“Caregivers are the unsung heroes who pour their hearts into nurturing, guiding, and shaping the futures of the little ones they embrace”</p>
          <div className={"employer-image-container"}>
            <img src={nannyImage} alt={"Employer Image"} />
            <div className={"employer-name"}>
              <p>Veres Panna</p>
              <p>Employer</p>
            </div>
          </div>
        </div>
      </div>
      <div className={"login-right-container"}>
        <lottie-player
          src="Assets/78126.json"
          background="transparent"
          speed="1"
          style={{
            width: "180px",
            height: "180px",
          }}
          loop
          autoplay
        ></lottie-player>
        <p className={"login-help-text"}>Kindly provide the following details to login in to your account</p>
        <form onSubmit={handleSubmit}>
          <StyledInputField
            required
            id="outlined-required-1"
            label="Enter Email"
            placeholder="Eg. example@gmail.com"
            value={email}
            onChange={(e) => { setEmail(e.target.value) }}
            type={"email"}
          />
          <StyledInputField
            required
            id="outlined-required-2"
            label="Password"
            placeholder="Eg. 1234567"
            value={password}
            onChange={(e) => { setPassword(e.target.value) }}
            type={"password"}
          />

          {errorMessage && (
            <p style={{ color: "red", textAlign: "center", marginBottom: "32px !important" }}>
              {errorMessage}
            </p>
          )}
          <button type={"submit"} className={"login-form-button"}>Login</button>
        </form>
        <div className={"login-bottom-section"}>
          <Link
            to="/register"
            style={{ color: "green", textDecoration: "none" }}
          >
            Create Account
          </Link>
          <Link
            to="/reset-password"
            style={{ color: "#9C27B0", textDecoration: "none" }}
          >
            Reset Password?
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
