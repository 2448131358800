// Component for the second and third image design combined
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import nannyImg from "./bg/nanny.jpeg";
import certificateSVG from "./svgFiles/certificate-svgrepo-com (1).svg";
import '../../CSS/CaregiverList.css';
import '../../CSS/Services.css';

export const CaregiverListing = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const navigate = useNavigate();
  return (
    <div className={"caregiver-list-section"}>
      <div className={"caregiver-list-section-inner"}>
        <div className={"caregiver-list-title-section"}>
          <h3 className={"caregiver-list-title"}>Key feature of our Caregiver</h3>
          <p className={"caregiver-list-title-description"}>House helps are trained in household tasks and certified before joining
            our platform.</p>
        </div>
        <div className={"services-chips-section"}>
          <div className={"best-services-chip"}>
            Emergency Childcare
            <i className={"bi bi-x"}></i>
          </div>
          <div className={"best-services-chip"}>
            Newborn Care
            <i className={"bi bi-x"}></i>
          </div>
          <div className={"best-services-chip"}>
            Newborn Care
            <i className={"bi bi-x"}></i>
          </div>
          <div className={"best-services-chip"}>
            Emergency Childcare
            <i className={"bi bi-x"}></i>
          </div>
          <div className={"best-services-chip"}>
            Newborn Care
            <i className={"bi bi-x"}></i>
          </div>
        </div>

        {/* Caregiver Cards */}
        <div className="row caregiver-cards-section">
          {Array(6)
            .fill(null)
            .map((_, index) => (
              <div key={index} className={`col-12 col-md-6 col-lg-4 caregiver-card-parent ${index < 3 && !isMobile ? 'mb-4': ''}`}>
                <div className={"caregiver-card"}>
                  <div className={"caregiver-card-top-section"}>
                    <img
                      src={nannyImg}
                      className={"caregiver-image"}
                      alt="Caregiver"
                    />
                    <div className={"caregiver-name-and-title"}>
                      <p>Veres Panna</p>
                      <p>Toddler Care</p>
                    </div>
                    <div className={"caregiver-rate"}>$25/h</div>
                  </div>
                  <div className="caregiver-card-description">
                    <p>
                      I specialize in infant care, and my priority is ensuring
                      your baby feels loved, safe, and happy.
                    </p>
                  </div>
                  <div className={"caregiver-card-details"}>
                    <div className={"caregiver-card-location"}>
                      <i className={"bi bi-geo-alt"}></i>
                      <p>Langatta, NHC</p>
                    </div>
                    <div className={"caregiver-card-speciality"}>
                      <img src={certificateSVG} alt={"Speciality"} width={"24"} height={"24"}/>
                      <p>Full-Time</p>
                    </div>
                  </div>
                  <button type={"button"} className={"hire-me-button"} onClick={() => navigate("/login")}>Hire Me</button>
                </div>
              </div>
            ))}
        </div>
        <button type={"button"} className={"explore-more-button"}>Explore More</button>
      </div>
    </div>
  );
};
