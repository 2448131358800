import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
  Box,
} from "@mui/material";
import {
  getLocalStorageVariable,
  postDataToAPI,
  postFileDataToAPI,
  safeJsonParse,
} from "../Shared/shared";
import { useLocation, useNavigate } from "react-router-dom";
import { server } from "../configs/configs";
import { homeOwner } from "../Shared/sharedLists";
import MpesaPaymentModal from "./MpesaPaymentModal";
import ViewConnectModal from "./ViewConnectModal";
import IdVerificationModal from "./IdVerificationModal";

const profilePicFolder = `${server}/profilePics`;

const ViewProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state || {};
  const email = data.email || "";
  const sessionType = data.sessionType || "";
  const [userData, setUserData] = useState({});
  const [moreInfoData, setMoreInfoData] = useState({});
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [profileData, setProfileData] = useState({});
  const userEmail = getLocalStorageVariable("userEmail");
  const [showViewConnectModal, setShowViewConnectModal] = useState(false);
  const [showIdVerificationModal, setShowIdVerificationModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenIdVerificationModal = () => setShowIdVerificationModal(true);
  const handleCloseIdVerificationModal = () =>
    setShowIdVerificationModal(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      const formData = new FormData();
      formData.append("email", email);
      const response = await postFileDataToAPI(
        "/dashboard/fetchProfileData.php",
        formData
      );
      if (response) {
        setUserData(response);
      }
    };

    const fetchMoreInfo = async () => {
      const formData = new FormData();
      formData.append("email", email);
      const response = await postFileDataToAPI(
        "/dashboard/fetchMoreInfoData.php",
        formData
      );
      if (response) setMoreInfoData(response);
    };

    fetchUserDetails();
    fetchMoreInfo();
  }, [email]);

  const ViewTraininingsUndergone = (email) => {
    navigate("/seeTrainings", { state: { email } });
  };

  const fetchUserData = async () => {
    if (!userEmail) return;

    const users = await postDataToAPI("/dashboard/fetchAllUsers.php", {});
    const user = users.find((u) => u.email === userEmail);

    if (user) {
      setProfileData(user);
    }
  };

  const checkPaymentStatus = async () => {
    const formData = new FormData();
    formData.append("connectId", userData.id);
    formData.append("userEmail", userEmail);
    formData.append("paymentType", "connect");

    const response = await postDataToAPI(
      "/dashboard/checkIfConnectPaid.php",
      formData
    );

    if (response === "Success") {
      setShowContactInfo(true);
    }
  };

  const handleCloseViewConnectModal = () => {
    checkPaymentStatus();
    setShowViewConnectModal(false);
  };

  useEffect(() => {
    // Fetch profile data on initial load
    fetchUserData();
  }, []);

  useEffect(() => {
    //Check If User Has paid to see contact info
    checkPaymentStatus();
  }, [userData]);

  const handleOpenViewConnectModal = () => {
    setShowViewConnectModal(true);
  };
  const displayContactInfo = async () => {
    handleOpenViewConnectModal();
    const formData = new FormData();
    formData.append("userMail", userEmail);
    formData.append("connectEmail", profileData.email);
    formData.append("connectPhoneNumber", profileData.phoneNumber);

    const response = await postDataToAPI(
      "/Emails/sendConnectEmails.php",
      formData
    );
    if (response === "Email Sent") {
      //console.log("We have sent contact info to your email.");
    }
  };

  const onIdVerificationSuccess = () => {
    displayContactInfo();
  };

  const handleOnSuccess = async () => {
    const verifyData = new FormData();
    verifyData.append("userMail", userEmail);
    const verificationRes = await postDataToAPI(
      "/IPRS/checkIfUserIdIsVerified.php",
      verifyData
    );
    if (verificationRes === "Success") {
      const updateIdData = new FormData();
      updateIdData.append("connectId", profileData.id);
      updateIdData.append("userEmail", userEmail);

      const updateRes = await postDataToAPI(
        "/IPRS/updateIdListArray.php",
        updateIdData
      );
      if (updateRes.success) {
        console.log("Updated Id Records");
      }
      checkPaymentStatus();
      displayContactInfo();
    } else {
      handleOpenIdVerificationModal();
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="dashboard-container">
      <Box
        className="employer-dashboard-page"
        sx={{
          // padding: "16px",
          // backgroundColor: "#f5f5f5",
          // minHeight: "100vh",
          // display: "flex",
          // flexDirection: "column",
          // alignItems: "center",
        }}
      >
        <Card sx={{ maxWidth: "100%", width: "100%", boxShadow: 3 }}>
          <div
            style={{
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                margin: "10px 0",
              }}
            >
              <img
                alt=""
                src={
                  userData.profilePic
                    ? `${profilePicFolder}/${userData.profilePic}`
                    : `${profilePicFolder}/defaultPIC.jpg`
                }
                style={{
                  width: userData.profilePic ? "300px" : "150px",
                  height: userData.profilePic ? "auto" : "150px",
                  borderRadius: userData.profilePic ? "0%" : "50%",
                  objectFit: "cover",
                  border: "2px solid #642D92",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
              />
            </div>
          </div>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" fontWeight="bold">
                  Full Name:
                </Typography>
                <Typography variant="body2">{userData.fullName}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" fontWeight="bold">
                  Date of Birth:
                </Typography>
                <Typography variant="body2">{userData.dateOfBirth}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" fontWeight="bold">
                  Address:
                </Typography>
                <Typography variant="body2">{userData.address}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" fontWeight="bold">
                  Country:
                </Typography>
                <Typography variant="body2">
                  {userData.selectedCountry}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" fontWeight="bold">
                  Tribe:
                </Typography>
                <Typography variant="body2">{userData.tribe}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" fontWeight="bold">
                  Sex:
                </Typography>
                <Typography variant="body2">{userData.sex}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" fontWeight="bold">
                  Religion:
                </Typography>
                <Typography variant="body2">{userData.religion}</Typography>
              </Grid>
              {showContactInfo ? (
                <>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" fontWeight="bold">
                      Phone Number:
                    </Typography>
                    <Typography variant="body2">
                      {userData.phoneNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" fontWeight="bold">
                      Email:
                    </Typography>
                    <Typography variant="body2">{userData.email}</Typography>
                  </Grid>
                </>
              ) : (
                null
              )}
            </Grid>
            {!showContactInfo &&
              <>
                <Divider sx={{ marginY: 2 }} className="w-100" />
                <Grid item xs={12} sm={6}>
                  <button
                    className="btn btn-primary"
                    style={{
                      backgroundColor: "purple",
                      alignItems: "left",
                      justifyContent: "left",
                    }}
                    onClick={() => setIsModalOpen(true)}
                  >
                    Connect with {userData.fullName}
                  </button>
                </Grid>
              </>}
            {sessionType === homeOwner && (
              <div>
                <Typography variant="h6" gutterBottom>
                  More Information
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" fontWeight="bold">
                      Desired Pay:
                    </Typography>
                    <Typography variant="body2">
                      {userData.employeeRates}
                    </Typography>
                    <button
                      onClick={() => ViewTraininingsUndergone(userData.email)}
                    >
                      View Trainings Attended
                    </button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" fontWeight="bold">
                      Availability:
                    </Typography>
                    <Typography variant="body2">
                      {moreInfoData.availability}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" fontWeight="bold">
                      Years of Experience:
                    </Typography>
                    <Typography variant="body2">
                      {moreInfoData.yearsOfExperience}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body1" fontWeight="bold">
                      Criminal Record:
                    </Typography>
                    <Typography variant="body2">
                      {moreInfoData.criminalRecord}:
                      {moreInfoData.criminalRecordExplanation}
                    </Typography>
                  </Grid>
                  <div style={{ padding: "20px", width: "100%" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6" fontWeight="bold">
                          Description:
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {moreInfoData.description || "Not specified"}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="h6" fontWeight="bold">
                          Languages Spoken:
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {moreInfoData.languagesSpoken || "Not specified"}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="h6" fontWeight="bold">
                          Preferred Age Groups:
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {moreInfoData.preferredAgeGroups
                            ? JSON.parse(moreInfoData.preferredAgeGroups).join(
                              ", "
                            )
                            : "Not specified"}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="h6" fontWeight="bold">
                          Referees:
                        </Typography>
                        {moreInfoData.referees ? (
                          safeJsonParse(JSON.parse(moreInfoData.referees))?.map(
                            (referee, index) => (
                              <Typography
                                key={index}
                                variant="body2"
                                color="textSecondary"
                              >
                                {referee.fullName} - {referee.phoneNumber}
                              </Typography>
                            )
                          ) || (
                            <Typography variant="body2" color="textSecondary">
                              Not specified
                            </Typography>
                          )
                        ) : (
                          <Typography variant="body2" color="textSecondary">
                            Not specified
                          </Typography>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="h6" fontWeight="bold">
                          Next Of Kin:
                        </Typography>
                        {moreInfoData.nextOfKin ? (
                          safeJsonParse(
                            JSON.parse(moreInfoData.nextOfKin)
                          )?.map((kin, index) => (
                            <Typography
                              key={index}
                              variant="body2"
                              color="textSecondary"
                            >
                              {kin.fullName} - {kin.phoneNumber} -{" "}
                              {kin.relationShip}
                            </Typography>
                          )) || (
                            <Typography variant="body2" color="textSecondary">
                              Not specified
                            </Typography>
                          )
                        ) : (
                          <Typography variant="body2" color="textSecondary">
                            Not specified
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </div>
            )}
          </CardContent>
        </Card>
      </Box>
      <MpesaPaymentModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSuccess={handleOnSuccess}
        connectId={userData.id}
        regType={sessionType}
      />

      <ViewConnectModal
        isOpen={showViewConnectModal}
        onClose={handleCloseViewConnectModal}
        connectEmail={userData.email}
        connectPhoneNumber={userData.phoneNumber}
      />

      <IdVerificationModal
        isOpen={showIdVerificationModal}
        onClose={handleCloseIdVerificationModal}
        onIdVerificationSuccess={onIdVerificationSuccess}
        connectId={userData.id}
      />
    </div>
  );
};

export default ViewProfile;
