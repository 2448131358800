import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import {
  getLocalStorageVariable,
  postDataToAPI,
  postFileDataToAPI,
} from "../Shared/shared";
import MpesaPaymentModal from "../components/MpesaPaymentModal";
import ViewConnectModal from "../components/ViewConnectModal";
import IdVerificationModal from "../components/IdVerificationModal";
import { useNavigate } from "react-router-dom";
import { CIcon } from "@coreui/icons-react";
import { cibSkillshare } from "@coreui/icons";

function ListJobs() {
  const navigate = useNavigate();
  const userEmail = getLocalStorageVariable("userEmail");
  const [jobs, setJobs] = useState([]);
  const [appliedJobs, setAppliedJobs] = useState([]);
  const role = getLocalStorageVariable("role");
  const [regType, setRegType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [jobId, setJobId] = useState("");
  const [showViewConnectModal, setShowViewConnectModal] = useState(false);
  const [showIdVerificationModal, setShowIdVerificationModal] = useState(false);
  const [locationFilter, setLocationFilter] = useState("");
  const [payFilter, setPayFilter] = useState("");
  const [connectEmail, setConnectEmail] = useState("");
  const [theConnectStatus, setTheConnectStatus] = useState(false);
  const [connectPhoneNumber, setConnectPhoneNumber] = useState("");

  const handleOpenIdVerificationModal = () => setShowIdVerificationModal(true);
  const handleCloseIdVerificationModal = () =>
    setShowIdVerificationModal(false);
  const handleOpenViewConnectModal = () => setShowViewConnectModal(true);
  const handleCloseViewConnectModal = () => setShowViewConnectModal(false);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const formData = new FormData();
        formData.append("role", role);
        const response = await postDataToAPI(
          "/dashboard/fetchJobs.php",
          formData
        );
        let data;

        try {
          data = typeof response === "string" ? JSON.parse(response) : response;
        } catch (parseError) {
          console.error("Failed to parse response as JSON:", parseError);
          return;
        }

        setJobs(data);
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };

    fetchJobs();
  }, []);

  useEffect(() => {
    const fetchAppliedJobs = async () => {
      const formData = new FormData();
      formData.append("userEmail", userEmail);

      const response = await postDataToAPI(
        "/dashboard/fetchAppliedJobs.php",
        formData
      );
      if (response) {
        setAppliedJobs(response);
      }
    };

    fetchAppliedJobs();
  }, []);

  const redirectToProfileComplete = (jobId) => {
    navigate("/completeProfile", { state: { jobId } });
  };

  const handleApply = async (jobId, connectStatus = false) => {
    const formData = new FormData();
    formData.append("connectId", jobId);
    formData.append("userEmail", userEmail);
    formData.append("paymentType", "jobApplication");
    const response = await postDataToAPI(
      "/dashboard/checkIfConnectPaid.php",
      formData
    );

    const verifiedData = new FormData();
    verifiedData.append("userEmail", userEmail);
    const verifiedResponse = await postDataToAPI(
      "/IPRS/checkIfUserIdIsVerified.php",
      verifiedData
    );

    if (response === "Success") {
      if (verifiedResponse === "Success") {
        if (connectStatus) {
          console.log("yess....");
          console.log("connectStatus: ", connectStatus);
          displayContactNow();
        } else {
          console.log("Not");
          console.log("connectStatus: ", connectStatus);
          redirectToProfileComplete(jobId);
        }
      } else {
        setJobId(jobId);
        setRegType("jobApplication");
        handleOpenIdVerificationModal();
      }
    } else {
      setJobId(jobId);
      setRegType("jobApplication");
      setIsModalOpen(true);
    }
  };

  const handleView = (jobId) => {
    navigate("/viewJob", { state: { jobId } });
  };

  const onIdVerificationSuccess = () => {
    handleCloseIdVerificationModal();
    redirectToProfileComplete(jobId);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOnSuccess = async () => {
    const verifyData = new FormData();
    verifyData.append("userEmail", userEmail);
    const verificationRes = await postDataToAPI(
      "/IPRS/checkIfUserIdIsVerified.php",
      verifyData
    );
    if (verificationRes === "Success") {
      if (theConnectStatus) {
        displayContactNow();
      } else {
        redirectToProfileComplete(jobId);
      }
    } else {
      handleOpenIdVerificationModal();
    }
  };

  const handleFilter = (job) => {
    const matchesLocation =
      locationFilter === "" ||
      job.jobLocation.toLowerCase().includes(locationFilter.toLowerCase());
    const matchesPay =
      payFilter === "" || parseFloat(job.pay) <= parseFloat(payFilter);

    return matchesLocation && matchesPay;
  };

  const handleOpenContactModal = (email, jobId) => {
    setTheConnectStatus(true);
    setConnectEmail(email);
    handleApply(jobId, true);
  };

  const displayContactNow = async () => {
    const formData = new FormData();
    formData.append("email", connectEmail);
    const response = await postFileDataToAPI(
      "/dashboard/fetchProfileData.php",
      formData
    );
    if (response) {
      setConnectPhoneNumber(response.phoneNumber);
      setShowViewConnectModal(true);
    }
  };

  return (
    <div className="employer-dashboard-page">
      <h3 style={{ textAlign: "center", marginBottom: "32px !important", color: "#333" }} className="mb-4">
        Available Jobs
      </h3>
      <div>
        <div className="row">
          <div className="col-sm-12">
            <div className="row inputs-section">
              <div className="col-sm-6">
                <div>
                  <input
                    type="text"
                    value={locationFilter}
                    onChange={(e) => setLocationFilter(e.target.value)}
                    placeholder="Filter by location"
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      width: "100%",
                      padding: "16px"
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div>
                  <input
                    type="number"
                    value={payFilter}
                    onChange={(e) => setPayFilter(e.target.value)}
                    placeholder="Filter by max pay"
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      width: "100%",
                      padding: "16px"
                    }}
                  />
                </div>
              </div>
            </div>

            {/* Jobs Section */}
            {jobs.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  gap: "32px",
                  flexWrap: "wrap"
                }}
                className="mt-4"
              >
                {jobs.filter(handleFilter).map((job) => {
                  const appliedJob = appliedJobs.find(
                    (applied) => applied.jobId === job.id
                  );

                  return (
                    <div
                      key={job.id}
                      className={"job-card"}
                    >
                      <div className={"skill-icon-bg"}>
                        <CIcon icon={cibSkillshare} className={"skill-icon"} size="xl" />
                      </div>
                      <p className={"role-text"}>
                        {job.role[0].toUpperCase() + job.role.substring(1)}
                      </p>
                      <div className="job-details-section">
                        <p>
                          <strong>Location:</strong> {job.jobLocation}
                        </p>
                        <p>
                          <strong>Years Of Experience:</strong>{" "}
                          {job.yearsOfExperience}
                        </p>
                        <p>
                          <strong>Minimum Qualifications:</strong>{" "}
                          {job.minimumQualifications}
                        </p>
                        <p>
                          <strong>Pay:</strong> {job.pay} KES
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "16px",
                          alignItems: "space-between",
                          justifyContent: "flex-start",
                        }}
                      >
                        {appliedJob ? (
                          <span
                            style={{ color: "#28a745", fontWeight: "bold" }}
                          >
                            {appliedJob.status}
                          </span>
                        ) : (
                          <button
                            onClick={() => handleApply(job.id)}
                            style={{
                              color: "#fff",
                              border: "none",
                              borderRadius: "5px",
                              padding: "8px",
                              backgroundColor: "purple",
                              width: "30%",
                              flexGrow: "1"
                            }}
                          >
                            Apply
                          </button>
                        )}
                        <button
                          onClick={() => handleView(job.id)}
                          style={{
                            backgroundColor: "black",
                            color: "#fff",
                            border: "none",
                            borderRadius: "5px",
                            padding: "8px",
                            width: "30%",
                            flexGrow: "1"
                          }}
                        >
                          View
                        </button>
                        <button
                          className="btn btn-success"
                          style={{ color: "white", border: "0px transparent solid", padding: "8px", width: "30%", flexGrow: "1" }}
                          onClick={() =>
                            handleOpenContactModal(job.email, job.id)
                          }
                        >
                          Contact
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p style={{ textAlign: "center", color: "#555" }}>
                No jobs available.
              </p>
            )}
          </div>
        </div>
      </div>
      <MpesaPaymentModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSuccess={handleOnSuccess}
        connectId={jobId}
        regType={"jobApplication"}
      />

      <IdVerificationModal
        isOpen={showIdVerificationModal}
        onClose={handleCloseIdVerificationModal}
        onIdVerificationSuccess={onIdVerificationSuccess}
        connectId={""}
      />

      <ViewConnectModal
        isOpen={showViewConnectModal}
        onClose={handleCloseViewConnectModal}
        connectEmail={connectEmail}
        connectPhoneNumber={connectPhoneNumber}
      />
    </div>
  );
}

export default ListJobs;
