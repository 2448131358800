import React, { useState } from "react";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import { Chef, Gardener, homeOwner, houseHelp, userSkills } from "../Shared/sharedLists";
import { getLocalStorageVariable, postDataToAPI } from "../Shared/shared";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import MpesaPaymentModal from "../components/MpesaPaymentModal";

function PostJobs() {
  const Role = getLocalStorageVariable('role');
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const [employmentType, setEmploymentType] = useState("");
  const [jobLocation, setJobLocation] = useState("");
  const [numberOfDays, setNumberOfDays] = useState("");
  const [pay, setPay] = useState("");
  const [description, setDescription] = useState("");
  const [yearsOfExperience, setYearsOfExperience] = useState("");
  const [minimumQualifications, setMinimumQualifications] = useState("");
  const [skills, setSkills] = useState([]);
  const [jobId, setJobId] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userEmail = getLocalStorageVariable("userEmail");

  const handleSkillsChange = (event, newSkills) => {
    setSkills(newSkills);
  };

  const skillChoice =
    role === Chef
      ? userSkills.chef_skills
      : role === houseHelp
      ? userSkills.nanny_skills
      : role === Gardener
      ? userSkills.gardener_skills
      : [];

  const handlePayAndVerify = async (e) => {
    e.preventDefault();

    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOnSuccess = async () => {
    // Display a confirmation dialog
    const confirmation = await Swal.fire({
      title: "Confirm Job Details",
      html: `
        <div style="text-align: left;">
          <strong>Role:</strong> ${role}<br/>
          <strong>Job Type:</strong> ${employmentType}<br/>
          <strong>Location:</strong> ${jobLocation}<br/>
          <strong>Number of Days:</strong> ${numberOfDays}<br/>
          <strong>Pay:</strong> ${pay}<br/>
          <strong>years Of Experience:</strong> ${yearsOfExperience}<br/>
          <strong>minimum Qualifications:</strong> ${minimumQualifications}<br/>
          <strong>Description:</strong> ${description}<br/>
          <strong>Skills:</strong> ${skills.join(", ") || "None"}
        </div>
      `,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      cancelButtonText: "Edit",
    });

    if (confirmation.isConfirmed) {
      const formData = new FormData();
      formData.append("email", userEmail);
      formData.append("role", role);
      formData.append("employmentType", employmentType);
      formData.append("jobLocation", jobLocation);
      formData.append("numberOfDays", numberOfDays);
      formData.append("pay", pay);
      formData.append("yearsOfExperience", yearsOfExperience);
      formData.append("minimumQualifications", minimumQualifications);
      formData.append("description", description);
      formData.append("skills", JSON.stringify(skills));

      const response = await postDataToAPI("/dashboard/postJob.php", formData);

      if (response === "Success") {
        // Clear states
        setRole("");
        setDescription("");
        setEmploymentType("");
        setJobLocation("");
        setNumberOfDays("");
        setPay("");
        setSkills([]);

        Swal.fire({
          title: "Success!",
          text: "Job Created Successfully",
          icon: "success",
          timer: 3000,
        });
      } else {
        Swal.fire({
          title: "Failed!",
          text: "Job Creation Failed, try again later",
          icon: "error",
          timer: 3000,
        });
      }
    }
  };

  const seePostedJobs = () => {
    navigate("/MyJobs");
  };

    if (Role !== homeOwner) {
      return (
        <>
          <div className="adminDashboard">
            <h1>Access Denied!</h1>
            <p className="no-data-message">
              You must be a '{homeOwner}' to post jobs on this Interface.
            </p>
          </div>
        </>
      );
    }

  return (
    <>
      <div style={{ width: "100%", padding: "0", margin: "0" }}>
        <div style={{ maxWidth: "1200px", margin: "0 auto", padding: "20px" }}>
          <div style={{ marginBottom: "20px" }}>
            <div className="mb-4">
              <button onClick={seePostedJobs}>Your Posted Jobs</button>
            </div>
            <form onSubmit={handlePayAndVerify}>
              <div>
                <select
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  required
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "5px",
                    marginBottom: "15px",
                    border: "1px solid #ccc",
                  }}
                >
                  <option value="">Select Type of Care</option>
                  <option value={houseHelp}>House Help</option>
                  <option value={Chef}>Chef</option>
                  <option value={Gardener}>Gardener</option>
                </select>
              </div>
              <div>
                <select
                  value={employmentType}
                  onChange={(e) => setEmploymentType(e.target.value)}
                  required
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "5px",
                    marginBottom: "15px",
                    border: "1px solid #ccc",
                  }}
                >
                  <option value="">Select Job Type</option>
                  <option value="Part-Time">Part-Time</option>
                  <option value="Full-Time">Full-Time</option>
                  <option value="Occassional">Occassional</option>
                  <option value="Regular">Regular</option>
                </select>
              </div>
              <div>
                <select
                  value={yearsOfExperience}
                  onChange={(e) => setYearsOfExperience(e.target.value)}
                  required
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "5px",
                    marginBottom: "15px",
                    border: "1px solid #ccc",
                  }}
                >
                  <option value="">Select years of experience</option>
                  <option value="0-1 Years">0-1 years</option>
                  <option value="1-3 Years">1-3 years</option>
                  <option value="3-5 Years">3-5 Years</option>
                  <option value="Over 5 Years">Over 5 Years</option>
                </select>
              </div>
              <TextField
                type="text"
                label="Enter Job Location"
                variant="outlined"
                value={jobLocation}
                onChange={(e) => setJobLocation(e.target.value)}
                required
                fullWidth
                style={{ marginBottom: "15px" }}
              />
              <TextField
                type="text"
                label="Enter minimum qualifications"
                variant="outlined"
                value={minimumQualifications}
                onChange={(e) => setMinimumQualifications(e.target.value)}
                required
                fullWidth
                style={{ marginBottom: "15px" }}
              />
              <Autocomplete
                multiple
                options={skillChoice}
                value={skills}
                onChange={handleSkillsChange}
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox checked={selected} />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select Skills"
                  />
                )}
                style={{ marginBottom: "15px" }}
              />
              <TextField
                type="number"
                label="Enter Pay"
                variant="outlined"
                value={pay}
                onChange={(e) => setPay(e.target.value)}
                required
                fullWidth
                style={{ marginBottom: "15px" }}
              />
              <TextField
                type="number"
                label="Enter Number of days"
                variant="outlined"
                value={numberOfDays}
                onChange={(e) => setNumberOfDays(e.target.value)}
                required
                fullWidth
                style={{ marginBottom: "15px" }}
              />
              <textarea
                placeholder="Write a short description about the job"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                className="w-100"
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "5px",
                  // border: "1px solid #ccc",
                  marginBottom: "15px",
                  minHeight: "100px",
                }}
              />
              <button
                type="submit"
                style={{
                  backgroundColor: "#642D92",
                  color: "#fff",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  width: "100%" ,
                }}
              >
                Post Job
              </button>
            </form>
          </div>
        </div>
        <MpesaPaymentModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSuccess={handleOnSuccess}
          connectId={jobId}
          regType={"jobPosting"}
        />
      </div>
    </>
  );
}

export default PostJobs;
