import { useState } from "react";
import React from "react";
import Swal from "sweetalert2";
import { postDataToAPI } from "../../Shared/shared";
import { FooterTop } from "./FooterTop";
import mapImg from "./bg/mapImg.PNG"
import '../../CSS/ContactUs.css';

const initialState = {
  name: "",
  email: "",
  message: "",
};

export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });


  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataa = new FormData();
    formDataa.append("name", name);
    formDataa.append("userMail", email);
    formDataa.append("message", message);
    const responsee = await postDataToAPI("/Emails/sendVisitorEmails.php", formDataa);

    if (responsee === "Email Sent") {
      clearState();
      Swal.fire({
        title: "Success",
        text: "Message sent successfully! We will get back to you.",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      console.log("Error message: ", responsee);
      Swal.fire({
        title: "Error",
        text: "Failed to send Message, try again later",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }

  return (
    <>
      <div id="contact" className={"contact-us-section-container"}>
        <h3>Contact Us</h3>
        <hr />
        <p className={"contact-us-section-description"}>Please contact us today for more info</p>
        <div className={"maps-and-contact-form"}>
          <img src={mapImg} alt="" className={"map-image"} />
          <div className={"contact-form"}>
            <h3 className="contact-form-title">Get In Touch</h3>
            <p className={"contact-form-description"}>Please fill out the form below to send us an email and we will get back to you as soon as possible.</p>
            <form onSubmit={handleSubmit}>
              <div className={"name-email-section"}>
                <input type={"text"} id="name" name="name" placeholder={"Name*"} required onChange={handleChange} value={name} />
                <input type={"email"} id="email" name="email" placeholder={"Email*"} required onChange={handleChange} value={email} />
              </div>
              <textarea name={"message"} rows={"4"} placeholder={"Message"} required onChange={handleChange}>
              </textarea>
              <button type={"submit"}>Send Message</button>
            </form>
          </div>
        </div>
        <div className={"contact-info"}>
          <h3 className={"contact-info-title"}>Contact Info</h3>
          <div className={"contact-info-items"}>
            <div className={"contact-info-item"}>
              <i className={"bi bi-geo-alt"}></i>
              <p>{props.data ? props.data.address : "Loading..."}</p>
            </div>
            <div className={"contact-info-item"}>
              <i className={"bi bi-telephone"}></i>
              <p>{props.data ? props.data.phone : "Loading..."}</p>
            </div>
            <div className={"contact-info-item"}>
              <i className={"bi bi-envelope-at"}></i>
              <p>{props.data ? props.data.email : "Loading..."}</p>
            </div>
          </div>
          <div className={"contact-info-items"}>
            <div className={"contact-info-item"}>
              <a href={props.data ? props.data.facebook : "#"}>
                <i className={"bi bi-facebook"}></i>
                <p>Facebook</p>
              </a>
            </div>
            <div className={"contact-info-item"}>
              <a href={props.data ? props.data.twitter : "#"}>
                <i className={"bi bi-twitter-x"}></i>
                <p>Twitter</p>
              </a>
            </div>
            <div className={"contact-info-item"}>
              <a href={props.data ? props.data.youtube : "#"}>
                <i className={"bi bi-youtube"}></i>
                <p>YouTube</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <FooterTop />
      <div id="footer">
        <div className="container text-center">
          <p>
            Empowering chefs, gardeners, mothers and houses helps one connection at a time.
          </p>
        </div>
      </div>
    </>
  );
};
