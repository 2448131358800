import React, { useEffect, useState } from "react";
import { getLocalStorageVariable } from "./NavBar";
import { postDataToAPI } from "../Shared/shared";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function MyJobs() {
  const navigate = useNavigate();
  const userEmail = getLocalStorageVariable("userEmail");
  const [postedJobs, setPostedJobs] = useState([]);
  const [appliedJobs, setAppliedJobs] = useState([]);

  const fetchMyJobs = async () => {
    const formData = new FormData();
    formData.append("email", userEmail);

    const response = await postDataToAPI(
      "/dashboard/fetchJobsByEmail.php",
      formData
    );

    if (response) {
      setPostedJobs(response);
    }
  };

  useEffect(() => {
    fetchMyJobs();
  }, [userEmail]);

  useEffect(() => {
    if (postedJobs.length === 0) {
      return;
    }

    const fetchAppliedJobNumber = async () => {
      const detailsPromises = postedJobs.map(async (job) => {
        const formData = new FormData();
        formData.append("id", job.id);

        const response = await postDataToAPI(
          "/dashboard/fetchAllApplicants.php",
          formData
        );

        return { jobId: job.id, applicants: response?.length || 0 };
      });

      const allDetails = await Promise.all(detailsPromises);
      setAppliedJobs(allDetails);
    };

    fetchAppliedJobNumber();
  }, [postedJobs]);

  const getApplicantsCount = (jobId) => {
    const job = appliedJobs.find((applied) => applied.jobId === jobId);
    return job ? job.applicants : 0;
  };

  const viewPostedJobDetails = (jobId) => {
    navigate("/viewAppliCants", { state: { jobId } });
  };

  const handleView = (jobId) => {
    navigate("/viewJob", { state: { jobId } });
  };

  const disableJob = async (jobId, status) => {
    let newType;

    if (status === "Active") {
      newType = "InActive";
    } else {
      newType = "Active";
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You are about to Disable Job.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append("jobId", jobId);
        formData.append("status", newType);

        const response = await postDataToAPI(
          "/dashboard/toggleJobStatus.php.php",
          formData
        );
        if (response === "Success") {
          Swal.fire("Updated!", `Your Status Updated`, "success");
          fetchMyJobs();
        } else {
          Swal.fire("Failed!", `Failed To update Status`, "error");
        }
      }
    });
  };

  return (
    <div style={{ padding: "20px 0" }}>
      <div>
        <div className="row">
          <div className="col-sm-12">
            <h3 style={{ fontWeight: "600", color: "#333" }}>My Posted Jobs</h3>
            {postedJobs.length > 0 ? (
              <div className="row">
                {postedJobs.map((job) => (
                  <div key={job.id} className="col-md-4 mb-4">
                    <div
                      className="card"
                      style={{
                        border: "1px solid #e2e2e2",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                        borderRadius: "10px",
                        overflow: "hidden",
                      }}
                    >
                      <div className="card-body">
                        <h5 className="card-title" style={{ fontSize: "18px", fontWeight: "bold", color: "#333" }}>
                          {job.role}
                        </h5>
                        <p className="card-text" style={{ fontSize: "14px", color: "#666" }}>
                          <strong>Location:</strong> {job.jobLocation} <br />
                          <strong>Status:</strong> {job.status} <br />
                          <strong>Posted On:</strong> {job.timeStamp} <br />
                          <strong>Applicants:</strong> {getApplicantsCount(job.id)}
                        </p>
                        <div className="row" style={{ marginTop: "20px" }}>
                          <div className="col-sm-4">
                            <div
                              onClick={() => viewPostedJobDetails(job.id)}
                              style={{
                                display: "inline-block",
                                padding: "8px 16px",
                                fontSize: "16px",
                                textAlign: "center",
                                color: "#DE1777",
                                cursor: "pointer",
                                textDecoration: "underline",
                                border: "none",
                                transition: "color 0.3s ease, transform 0.3s ease",
                              }}
                              onMouseEnter={(e) => e.target.style.color = '#DE1777'}
                              onMouseLeave={(e) => e.target.style.color = '#DE1777'}
                            >
                              View Applicants
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div
                              onClick={() => handleView(job.id)}
                              style={{
                                display: "inline-block",
                                padding: "8px 16px",
                                fontSize: "16px",
                                textAlign: "center",
                                color: "purple",
                                cursor: "pointer",
                                textDecoration: "underline",
                                border: "none",
                                transition: "color 0.3s ease, transform 0.3s ease",
                              }}
                              onMouseEnter={(e) => e.target.style.color = 'purple'}
                              onMouseLeave={(e) => e.target.style.color = 'purple'}
                            >
                              Job Details
                            </div>
                          </div>
                          <div className="col-sm-4">
                            {job.status === "Active" ? (
                              <div
                                onClick={() => disableJob(job.id, job.status)}
                                style={{
                                  display: "inline-block",
                                  padding: "8px 16px",
                                  fontSize: "16px",
                                  textAlign: "center",
                                  color: "red",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                  border: "none",
                                  transition: "color 0.3s ease, transform 0.3s ease",
                                }}
                                onMouseEnter={(e) => e.target.style.color = 'red'}
                                onMouseLeave={(e) => e.target.style.color = 'red'}
                              >
                                Disable Advert
                              </div>
                            ) : (
                              <div
                                onClick={() => disableJob(job.id, job.status)}
                                style={{
                                  display: "inline-block",
                                  padding: "8px 16px",
                                  fontSize: "16px",
                                  textAlign: "center",
                                  color: "green",
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                  border: "none",
                                  transition: "color 0.3s ease, transform 0.3s ease",
                                }}
                                onMouseEnter={(e) => e.target.style.color = 'green'}
                                onMouseLeave={(e) => e.target.style.color = 'green'}
                              >
                                Enable Advert
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p style={{ fontSize: "16px", color: "#888" }}>No jobs posted yet.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyJobs;
