import React from "react";
import groupSVG from "./svgFiles/Group 14413.svg";
import aboutSVG from "./svgFiles/about-faq-help-question-svgrepo-com.svg";
import blogSVG from "./svgFiles/blog-svgrepo-com.svg";
import certificateSVG from "./svgFiles/certificate-svgrepo-com (1).svg";
import screenerSVG from "./svgFiles/screener-svgrepo-com.svg";
import trainingSVG from "./svgFiles/training-board-svgrepo-com.svg";
import "../../CSS/Features.css";

export const Features = (props) => {
  return (
    <div id="aboutus" className={"features-container"}>
      <div className={"features-container-inner"}>
        <div className={"features-intro"}>
          <h3>About Us</h3>
          <p className={"features-intro-text"}>
            At Nannies Desk, we understand the importance of finding the right
            support for your family and the challenges faced by those seeking
            meaningful work. That’s why we’ve created a platform that connects
            families with caring, dependable nannies in a way that’s safe,
            seamless, and stress-free. Our mission is to provide mothers and
            families with peace of mind, knowing they can rely on a trusted
            resource to find skilled and compassionate domestic help. At the
            same time, we’re dedicated to empowering nannies by offering them
            opportunities for stable, fair, and respectful employment. Through
            our app, families can access a carefully curated pool of
            pre-screened, well-trained, and professional nannies who are ready
            to provide the care and support your household deserves. At Nannies
            Desk, our vision is to build a community of trust and excellence,
            becoming the go-to provider for household help across Africa and
            beyond—one connection at a time.
          </p>
          <h3>Why Choose Us?</h3>
          <div className={"features-list-left-item"}>
            <i className={"bi bi-caret-right-fill"}></i>
            <p>Seemless use of our platform!</p>
          </div>
          <div className={"features-list-left-item"}>
            <i className={"bi bi-caret-right-fill"}></i>
            <p>
              Linking you with nannies,garners, chefs or employers that match
              your profile.
            </p>
          </div>
          <div className={"features-list-left-item"}>
            <i className={"bi bi-caret-right-fill"}></i>
            <p>Background checks</p>
          </div>
          <div className={"features-list-left-item"}>
            <i className={"bi bi-caret-right-fill"}></i>
            <p> We are here incase of any issues you have</p>
          </div>
        </div>

        <div className={"features-list-right-items"}>
          <div className={"features-list-right-item"}>
            <div className={"features-list-item-group"}>
              <img src={groupSVG} alt={"Group SVG"} />
              <h3>Verified Nanny Profiles</h3>
              <p>
                All house helps on our platform are pre-screened and trained,
                ensuring you only hire the best.
              </p>
            </div>
            <div className={"features-list-item-group"}>
              <img src={blogSVG} alt={"Blog SVG"} />
              <h3>Blog</h3>
              <p>
                Read articles on domestic workforce trends, parenting tips, and
                success stories from families and house helps using our
                platform.
              </p>
            </div>
          </div>
          <div className={"features-list-right-item"}>
            <div className={"features-list-item-group"}>
              <img src={certificateSVG} alt={"Certificate SVG"} />
              <h3>Training &amp; Certification</h3>
              <p>
                House helps are trained in household tasks and certified before
                joining our platform.
              </p>
            </div>
            <div className={"features-list-item-group"}>
              <img src={aboutSVG} alt={"About SVG"} />
              <h3>FAQ</h3>
              <p>
                Find answers to common questions about our services, how the app
                works, and how we ensure safety and quality.
              </p>
            </div>
          </div>
          <div className={"features-list-right-item"}>
            <div className={"features-list-item-group"}>
              <img src={trainingSVG} alt={"Training SVG"} />
              <h3>Training Programs</h3>
              <p>
                All house helps on our platform are pre-screened and trained,
                ensure you hire only the best.
              </p>
            </div>
            <div className={"features-list-item-group"}>
              <img src={screenerSVG} alt={"About SVG"} />
              <h3>Safe Employer Screening</h3>
              <p>
                We also screen employers, ensuring a safe working environment
                for house helps.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
