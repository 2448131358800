import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { postDataToAPI } from "../Shared/shared";
import { homeOwner } from "../Shared/sharedLists";
import Swal from "sweetalert2";
import ScheduleInterview from "./ScheduleInterview";
import ViewConnectModal from "./ViewConnectModal";

function ViewApplicants() {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state || {};
  const jobId = data.jobId || "";
  const [applicants, setApplicants] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [currentId, setCurrentId] = useState(jobId);
  const [scheduleToMail, setScheduleToMail] = useState("");
  const [scheduleToPhone, setScheduleToPhone] = useState("");
  const [showScheduleInterviewModal, setShowScheduleInterviewModal] =
    useState(false);
  const [showViewConnectModal, setShowViewConnectModal] = useState(false);
  const handleCloseViewConnectModal = () => setShowViewConnectModal(false);
  const handleOpenViewConnectModal = () => setShowViewConnectModal(true);

  // Fetch applicants based on the job ID
  const fetchApplicants = async () => {
    const formData = new FormData();
    formData.append("id", jobId);
    const response = await postDataToAPI(
      "/dashboard/fetchAllApplicants.php",
      formData
    );

    if (response) {
      setApplicants(response);
    }
  };

  useEffect(() => {
    if (!jobId) {
      return;
    }

    fetchApplicants();
  }, [jobId]);

  // Fetch user details and match emails with applicants
  useEffect(() => {
    if (applicants.length === 0) {
      return;
    }

    const fetchUserDetails = async () => {
      const response = await postDataToAPI("/dashboard/fetchAllUsers.php");

      if (response) {
        const matchedUsers = response
          .filter((user) =>
            applicants.some((applicant) => applicant.email === user.email)
          )
          .map((user) => {
            const applicant = applicants.find(
              (app) => app.email === user.email
            );
            return {
              ...user,
              status: applicant?.status || "Unknown",
              jobId: applicant?.jobId || "Unknown",
            };
          });
        setUserDetails(matchedUsers);
      }
    };

    fetchUserDetails();
  }, [applicants]);

  // Handle button actions
  const viewProfile = (email) => {
    const sessionType = homeOwner;
    navigate("/ViewProfile", { state: { email, sessionType } });
  };

  const rejectApplicant = (email) => {
    let newType = "Disqualified";

    Swal.fire({
      title: "Are you sure?",
      text: `You are about to disqualify the applicant.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Proceed with updating state and calling the API
        const formData = new FormData();
        formData.append("jobId", jobId);
        formData.append("email", email);
        formData.append("status", newType);

        const response = await postDataToAPI(
          "/dashboard/disqualifyApplicant.php",
          formData
        );
        // Show a success message
        if (response === "Success") {
          Swal.fire(
            "Disqualified!",
            `Applicant Disqualified Successfully!`,
            "success"
          );
          fetchApplicants();
        } else {
          Swal.fire("Failed!", `Failed To Disqualify Applicant`, "error");
        }
      }
    });
  };

  const handleView = (jobId) => {
    navigate("/viewJob", { state: { jobId } });
  };

  const handleCloseScheduleInterviewModal = () => {
    setShowScheduleInterviewModal(false);
  };

  const handleOpenScheduleInterviewModal = (email, phoneNumber) => {
    setShowScheduleInterviewModal(true);
    setScheduleToMail(email);
    setScheduleToPhone(phoneNumber);
  };

  const handleOpenContactModal = (email, phoneNumber) => {
    handleOpenViewConnectModal();
    setScheduleToMail(email);
    setScheduleToPhone(phoneNumber);
  };

  return (
    <div>
      <div className="row">
        <div className="col-sm-12">
          <div className="btn btn-primary" onClick={() => handleView(jobId)}>
            Job Details
          </div>
          {userDetails.length > 0 ? (
            <div className="row">
              {userDetails.map((user) => (
                <div key={user.id}>
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">{user.fullName}</h5>
                      <p className="card-text">
                        <strong>Address:</strong> {user.address} <br />
                        <strong>Role:</strong> {user.registrationType}
                      </p>
                      <button
                        className="btn btn-primary me-2"
                        onClick={() => viewProfile(user.email)}
                        style={{ margin: "5px" }}
                      >
                        View Profile
                      </button>
                      {user.status === "Applied" && user.jobId === currentId ? (
                        <button
                          className="btn btn-danger me-2"
                          onClick={() => rejectApplicant(user.email)}
                          style={{ margin: "5px" }}
                        >
                          Disqualify
                        </button>
                      ) : (
                        <button
                          className="btn btn-secondary me-2"
                          disabled
                          style={{ margin: "5px" }}
                        >
                          Disqualified
                        </button>
                      )}
                      <button
                        className="btn btn-success"
                        style={{ color: "white", margin: "5px" }}
                        onClick={() =>
                          handleOpenScheduleInterviewModal(
                            user.email,
                            user.phoneNumber
                          )
                        }
                      >
                        Schedule Interview
                      </button>
                      <button
                        className="btn btn-success"
                        style={{ color: "white", margin: "5px" }}
                        onClick={() =>
                          handleOpenContactModal(user.email, user.phoneNumber)
                        }
                      >
                        Contact
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>No applicants found.</p>
          )}
        </div>
      </div>

      <ScheduleInterview
        isOpen={showScheduleInterviewModal}
        onClose={handleCloseScheduleInterviewModal}
        email={scheduleToMail}
        phoneNumber={scheduleToPhone}
      />
      <ViewConnectModal
        isOpen={showViewConnectModal}
        onClose={handleCloseViewConnectModal}
        connectEmail={scheduleToMail}
        connectPhoneNumber={scheduleToPhone}
      />
    </div>
  );
}

export default ViewApplicants;
