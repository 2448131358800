import React from "react";
import '../../CSS/Services.css';

export const OurServices = () => {
  return (
    <div id="servicess" className={"services-container"}>
      <div className={"services-container-inner"}>
        <div className={"services-intro"}>
          <p>Our Happy Customer</p>
          <p>We have so many satisfied parents who are really happy to take our services</p>
        </div>
        <div className="scroll-down">
          <button type={"button"}>SCROLL DOWN
            <i className={"bi bi-caret-down"}></i>
          </button>
        </div>
        <div className={"best-services-section"}>
          <h3>Our Best Services</h3>
          <div className={"best-services"}>
            <div className={"best-services-chip"}>
              Emergency Childcare
              <i className={"bi bi-x"}></i>
            </div>
            <div className={"best-services-chip"}>
            Landscape design
              <i className={"bi bi-x"}></i>
            </div>
            <div className={"best-services-chip"}>
            Cooking techniques
              <i className={"bi bi-x"}></i>
            </div>
          </div>
          <div className={"best-services"}>
            <div className={"best-services-chip"}>
            Newborn Care
              <i className={"bi bi-x"}></i>
            </div>
            <div className={"best-services-chip"}>
              Gardener Services
              <i className={"bi bi-x"}></i>
            </div>
            <div className={"best-services-chip"}>
            Garnishing and decoration
              <i className={"bi bi-x"}></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
