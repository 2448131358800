import React, { useEffect, useState, useRef } from "react";
import { getLocalStorageVariable, postDataToAPI } from "../Shared/shared";
import Swal from "sweetalert2";

function Training() {
  const role = getLocalStorageVariable("role");
  const email = getLocalStorageVariable("userEmail");
  const [trainings, setTrainings] = useState([]);
  const [userUndergoneTrainings, setUserUndergoneTrainings] = useState([]);
  const [playerInitialized, setPlayerInitialized] = useState(false);

  const videoRefs = useRef({}); // Store references to YouTube players

  useEffect(() => {
    const fetchTrainings = async () => {
      try {
        const formData = new FormData();
        formData.append("role", role);
        const response = await postDataToAPI(
          "/dashboard/fetchTrainings.php",
          formData
        );

        let data;
        try {
          data = typeof response === "string" ? JSON.parse(response) : response;
          setTrainings(data);
        } catch (parseError) {
          console.error("Failed to parse response as JSON:", parseError);
         
        }
      } catch (error) {
        console.error("Error fetching trainings:", error);
       
      }
    };

    const fetchUndergoneTrainings = async () => {
      try {
        const formData = new FormData();
        formData.append("email", email);
        const response = await postDataToAPI(
          "/dashboard/fetchTrainingsUndergone.php",
          formData
        );

        if (response.length > 0) {
          setUserUndergoneTrainings(response);
        } else {
          console.error("No undergone trainings found.");
        }
      } catch (error) {
        Swal.fire("Error", "Failed to fetch undergone trainings.", "error");
      }
    };

    fetchTrainings();
    fetchUndergoneTrainings();
  }, [email, role]);

  useEffect(() => {
    const loadYouTubeAPI = () => {
      if (!window.YT) {
        const tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubeIframeAPIReady = () => {
          setPlayerInitialized(true);
        };
      } else {
        setPlayerInitialized(true);
      }
    };

    loadYouTubeAPI();
  }, []);

  const handleVideoWatched = async (trainingId) => {
    // Check if training is already marked as completed
    const isAlreadyCompleted = userUndergoneTrainings.some(
      (undergoneTraining) => undergoneTraining.trainingId === trainingId
    );

    if (isAlreadyCompleted) {
      Swal.fire(
        "Info",
        "This training has already been marked as watched.",
        "info"
      );
      return;
    }

    try {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("trainingId", trainingId);

      const response = await postDataToAPI(
        "/dashboard/markTrainingCompleted.php",
        formData
      );

      if (response.length > 0) {
        Swal.fire("Success", "Training marked as completed!", "success");

        // Update state immediately to reflect the change
        setUserUndergoneTrainings((prev) => [
          ...prev,
          { trainingId }
        ]);

        setTrainings((prev) =>
          prev.map((training) =>
            training.id === trainingId
              ? { ...training, completed: true }
              : training
          )
        );
      } else {
        Swal.fire(
          "Error",
          response.message || "Failed to update training.",
          "error"
        );
      }
    } catch (error) {
      Swal.fire("Error", "An error occurred while updating training.", "error");
    }
  };

  const containerStyle = {
    padding: "20px",
    maxWidth: "900px",
    margin: "0 auto",
  };

  const cardStyle = {
    border: "1px solid #ddd",
    borderRadius: "16px",
    padding: "16px",
    marginBottom: "20px",
    backgroundColor: "white",
    // boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
  };

  const iframeStyle = {
    width: "100%",
    height: "360px",
    borderRadius: "16px",
    marginTop: "16px"
  };

  const badgeStyle = {
    display: "inline-block",
    padding: "5px 10px",
    backgroundColor: "#28a745",
    color: "white",
    borderRadius: "5px",
    marginTop: "10px",
  };

  const getYouTubeEmbedLink = (url) => {
    const videoId =
      url.split("v=")[1]?.split("&")[0] || url.split("youtu.be/")[1];
    return `https://www.youtube.com/embed/${videoId}`;
  };

  return (
    <div className={"employer-dashboard-page"}>
      {trainings.length === 0 ? (
        <p style={{ textAlign: "center" }}>No trainings available.</p>
      ) : (
        trainings.map((training) => {
          const isCompleted = userUndergoneTrainings.some(
            (undergoneTraining) => undergoneTraining.trainingId === training.id
          );

          const iframeId = `youtube-player-${training.id}`;
          return (
            <div key={training.id} style={cardStyle}>
              <h3>{training.title}</h3>
              <p style={{padding: "16px 0"}}>
                <strong>Target Group:</strong> {training.targetGroup}
              </p>
              <p>{training.description}</p>
              <iframe
                id={iframeId}
                src={getYouTubeEmbedLink(training.link)}
                style={iframeStyle}
                allow="autoplay; encrypted-media"
                title={`Training Video ${training.id}`}
                frameBorder="0"
              ></iframe>

              {/* Button to mark as watched */}
              {!isCompleted && (
                <button
                  onClick={() => handleVideoWatched(training.id)}
                  style={{
                    marginTop: "10px",
                    padding: "10px 20px",
                    backgroundColor: "#28a745",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Mark as Watched
                </button>
              )}

              {isCompleted && <span style={badgeStyle}>Marked as Completed</span>}
            </div>
          );
        })
      )}
    </div>
  );
}

export default Training;
