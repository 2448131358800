import React, { useEffect, useRef, useState } from "react";
import "../CSS/Profile.css";
import {
  checkCameraPermissions,
  getLocalStorageVariable,
  postDataToAPI,
  postFileDataToAPI,
  safeJsonParse,
} from "../Shared/shared";
import Swal from "sweetalert2";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AdditionalInfoModal from "./AdditionalInfoModal";
import { Autocomplete, Checkbox, Switch, TextField } from "@mui/material";
import { server } from "../configs/configs";
import AboutInfoModal from "./AboutInfoModal";
import { Chef, Gardener, homeOwner, houseHelp } from "../Shared/sharedLists";
import { useNavigate } from "react-router-dom";
import MpesaPaymentModal from "./MpesaPaymentModal";
import IdVerificationModal from "./IdVerificationModal";

const profilePicFolder = `${server}/profilePics`;
const certificatePicFolder = `${server}/Certificates`;
const IdPicFolder = `${server}/Ids`;

const Profile = () => {
  const navigate = useNavigate();
  const userEmail = getLocalStorageVariable("userEmail");
  const [profileData, setProfileData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState("");
  const [availability, setAvailability] = useState("");
  const [userDetails, setUserDetails] = useState("");
  const [yearsOfExperience, setYearsOfExperience] = useState("");
  const [preferredAgeGroups, setPreferredAgeGroups] = useState([]);
  const [currentEmploymentStatus, setCurrentEmploymentStatus] = useState("");
  const [languagesSpoken, setLanguagesSpoken] = useState("");
  const [criminalRecord, setCriminalRecord] = useState("");
  const [newRates, setNewRates] = useState("");
  const [userType, setUserType] = useState("");
  const [criminalRecordExplanation, setCriminalRecordExplanation] =
    useState("");
  const [idPicture, setIdPicture] = useState(null);
  const [idPictureBack, setIdPictureBack] = useState(null);
  const [certificatePicture, setCertificatePicture] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAdditionalInfoOpen, setIsAdditionalInfoOpen] = useState(true);
  const [isActivelyLooking, setIsActivelyLooking] = useState(false); // For chefs
  const [profilePicture, setProfilePicture] = useState("");
  const [skills, setSkills] = useState([]);
  const [additionalInfoData, setAdditionalInfoData] = useState({});
  const [showIdVerificationModal, setShowIdVerificationModal] = useState(false);
  const handleOpenIdVerificationModal = () => setShowIdVerificationModal(true);
  const handleCloseIdVerificationModal = () =>
    setShowIdVerificationModal(false);

  const [nextOfKin, setNextOfKin] = useState([
    { fullName: "", relationShip: "", phoneNumber: "" },
    { fullName: "", relationShip: "", phoneNumber: "" },
  ]);

  const [referees, setReferees] = useState([
    { fullName: "", phoneNumber: "" },
    { fullName: "", phoneNumber: "" },
  ]);

  // Collapsible state for sections
  const [isPersonalInfoOpen, setIsPersonalInfoOpen] = useState(true);
  const [isAboutMeOpen, setIsAboutMeOpen] = useState(true);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [cameraStream, setCameraStream] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const fetchAdditionalInfoData = async () => {
    const userEmail = getLocalStorageVariable("userEmail");

    const formData = new FormData();
    formData.append("email", userEmail);

    const user = await postDataToAPI(
      "/dashboard/fetchMoreInfoData.php",
      formData
    );

    if (user) {
      const userDetails = user;
      setUserDetails(userDetails);
      // Use optional chaining to avoid accessing properties of undefined
      setDescription(userDetails.description || "");
      setAvailability(userDetails.availability);
      setYearsOfExperience(userDetails.yearsOfExperience);
      setPreferredAgeGroups(userDetails.preferredAgeGroups);
      setCurrentEmploymentStatus(userDetails.currentEmploymentStatus);
      setLanguagesSpoken(userDetails.languagesSpoken);
      setCriminalRecord(userDetails.criminalRecord);
      setCriminalRecordExplanation(userDetails.criminalRecordExplanation);
      setIdPicture(userDetails.idPicture);
      setIdPictureBack(userDetails.idPictureBack);
      setCertificatePicture(userDetails.certificatePicture);

      const nextOfKinData = safeJsonParse(JSON.parse(userDetails.nextOfKin));
      const refereesData = safeJsonParse(JSON.parse(userDetails.referees));
      const Skills = safeJsonParse(JSON.parse(userDetails.skills));

      // if (Skills) {
      setSkills(Skills);
      // }

      if (nextOfKinData) {
        setNextOfKin(nextOfKinData);
      }

      if (refereesData) {
        setReferees(refereesData);
      }
    } else {
      setDescription("");
    }
  };

  const fetchUserData = async () => {
    const userEmail = getLocalStorageVariable("userEmail");
    const users = await postDataToAPI("/dashboard/fetchAllUsers.php", {});
    const user = users.find((u) => u.email === userEmail);

    if (user) {
      setProfileData(user);
      setProfilePicture(user.profilePic || "");
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    if (profileData.registrationType) {
      fetchAdditionalInfoData();
      if (profileData.activelyLooking === "Active") {
        setIsActivelyLooking(true);
      } else {
        setIsActivelyLooking(false);
      }
    }
  }, [profileData]);

  // Handle profile picture change
  const handleProfilePictureChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("profilePicture", file);
      formData.append("userEmail", profileData.email);

      const response = await postFileDataToAPI(
        "/dashboard/updateProfilePicture.php",
        formData
      );

      if (response === "Success") {
        Swal.fire(
          "Success",
          "Profile picture updated successfully!",
          "success"
        );
        fetchUserData();
      } else {
        Swal.fire("Failed!", "Profile picture update failed.", "error");
      }
    }
  };

  const stopCamera = () => {
    if (cameraStream) {
      cameraStream.getTracks().forEach((track) => track.stop());
    }
    setIsCameraOpen(false);
    setCameraStream(null);
  };

  const handleUploadSelfiePhoto = async (file) => {
    if (file) {
      const formData = new FormData();
      formData.append("profilePicture", file);
      formData.append("userEmail", profileData.email);

      const response = await postFileDataToAPI(
        "/dashboard/updateProfilePicture.php",
        formData
      );

      if (response === "Success") {
        Swal.fire(
          "Success",
          "Profile picture updated successfully!",
          "success"
        );
        fetchUserData();
      } else {
        Swal.fire("Failed!", "Profile picture update failed.", "error");
      }
    }
  };

  const startCamera = async () => {
    try {
      // Check if permissions have been granted
      const isPermissionGranted = await checkCameraPermissions();

      if (isPermissionGranted) {
        // Permissions are granted, start the camera
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        setCameraStream(stream);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
        setIsCameraOpen(true);
      } else {
        // Permission is denied, notify the user
        Swal.fire(
          "Permission Denied!",
          "Please allow camera access to use this feature.",
          "error"
        );
      }
    } catch (error) {
      console.error("Error accessing camera:", error);
      Swal.fire(
        "Error!",
        "Unable to access the camera. Please check your permissions.",
        "error"
      );
    }
  };

  const captureSelfie = () => {
    if (canvasRef.current && videoRef.current) {
      const canvas = canvasRef.current;
      const video = videoRef.current;
      const context = canvas.getContext("2d");

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      canvas.toBlob(async (blob) => {
        handleUploadSelfiePhoto(blob);
        stopCamera();
      });
    }
  };

  useEffect(() => {
    if (isCameraOpen) {
      startCamera();
    } else {
      stopCamera();
    }
  }, [isCameraOpen]);

  const handleCloseModal = () => {
    fetchAdditionalInfoData();
    setIsModalOpen(false);
  };

  // Toggle section
  const toggleSection = (section) => {
    switch (section) {
      case "personalInfo":
        setIsPersonalInfoOpen(!isPersonalInfoOpen);
        break;
      case "aboutMe":
        setIsAboutMeOpen(!isAboutMeOpen);
        break;
      case "additionalInfo":
        setIsAdditionalInfoOpen(!isAdditionalInfoOpen);
        break;
      default:
        break;
    }
  };

  const handleRatesChange = (rates, currentUser) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to update your pay Rates to ${rates}.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Proceed with updating state and calling the API
        const formData = new FormData();
        formData.append("id", profileData.id);
        formData.append("currentUser", currentUser);
        formData.append("rates", rates);
        const response = await postDataToAPI(
          "/dashboard/updateUserRates.php",
          formData
        );

        // Show a success message
        if (response === "Success") {
          fetchUserData();
          Swal.fire(
            "Updated!",
            `Your pay Rate has been updated to ${rates}.`,
            "success"
          ).then(() => {
            navigate("/profile");
            setTimeout(() => {
            }, 2000); // Reload the page after 2 seconds
          });
        } else {
          Swal.fire("Failed!", `Pay Rate update Failed`, "error");
        }
      }
    });
  };

  const handleSetNewRates = (theNewRates, theUserType) => {
    setNewRates(theNewRates);
    setUserType(theUserType);
  };

  const updateRegistrationType = (newType) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to update your registration type to ${newType}.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Proceed with updating state and calling the API
        const formData = new FormData();
        formData.append("id", profileData.id);
        formData.append("registrationType", newType);
        const response = await postDataToAPI(
          "/dashboard/updateRegistrationType.php",
          formData
        );

        // Show a success message
        if (response === "Success") {
          fetchUserData();
          navigate("/dashboard");
          Swal.fire(
            "Updated!",
            `Your registration type has been updated to ${newType}.`,
            "success"
          ).then(() => {
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
        } else {
          Swal.fire("Failed!", `Registration Type Failed`, "error");
        }
      }
    });
  };

  const handleActivelyLookingToggle = async () => {
    // Toggle the state first
    setIsActivelyLooking((prevState) => {
      const newState = !prevState;

      // Determine the new status based on the toggled state
      let newStatus = newState ? "Active" : "Inactive";

      // Send this update to the API
      const formData = new FormData();
      formData.append("email", localStorage.getItem("userEmail"));
      formData.append("newStatus", newStatus);

      postDataToAPI("/dashboard/updateIsActivelyLooking.php", formData)
        .then((response) => {
          if (response === "Success") {
            Swal.fire({
              title: "Success!",
              text: "Update Success",
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "Failed!",
              text: "Failed to update",
              icon: "error",
            });
          }
        })
        .catch((error) => {
          console.error("Error updating status:", error);
          Swal.fire({
            title: "Error!",
            text: "An error occurred while updating.",
            icon: "error",
          });
        });

      return newState; // Return the new state to update `isActivelyLooking`
    });
  };

  const redirectToProfileComplete = (jobId) => {
    navigate("/completeProfile", { state: { jobId } });
  };

  const handleEdit = async (jobId) => {
    //Check If They have ever paid Before
    const formDataa = new FormData();
    formDataa.append("userEmail", userEmail);
    const res = await postDataToAPI(
      "/dashboard/checkIfUserEverPaid.php",
      formDataa
    );
    if (res === "Success") {
      redirectToProfileComplete(0);
    } else {
      const formData = new FormData();
      formData.append("connectId", jobId);
      formData.append("userEmail", userEmail);
      formData.append("paymentType", "profileSetup");
      const response = await postDataToAPI(
        "/dashboard/checkIfConnectPaid.php",
        formData
      );

      const verifiedData = new FormData();
      verifiedData.append("userEmail", userEmail);
      const verifiedResponse = await postDataToAPI(
        "/IPRS/checkIfUserIdIsVerified.php",
        verifiedData
      );

      if (response === "Success") {
        if (verifiedResponse === "Success") {
          redirectToProfileComplete(0);
        } else {
          handleOpenIdVerificationModal();
        }
      } else {
        setIsModalOpen(true);
      }
    }
  };

  const onIdVerificationSuccess = () => {
    handleCloseIdVerificationModal();
    redirectToProfileComplete(0);
  };

  const handleOnSuccess = async () => {
    const verifyData = new FormData();
    verifyData.append("userEmail", userEmail);
    const verificationRes = await postDataToAPI(
      "/IPRS/checkIfUserIdIsVerified.php",
      verifyData
    );
    if (verificationRes === "Success") {
      redirectToProfileComplete(0);
    } else {
      handleOpenIdVerificationModal();
    }
  };

  const handleSeeMyTrainings = () => {
    navigate("/seeTrainings");
  };
  const handleSeeMyJobApplications = () => {
    navigate("/JobApplications");
  };

  return (
    <>
      <div>
        <div className="row">
          <div className="col-sm-12">
            {profileData ? (
              <>
                <div className="row">
                  <div className="col-sm-12 mb-4">
                    <button
                      onClick={handleSeeMyTrainings}
                      style={{ marginRight: "5px", backgroundColor: "black" }}
                    >
                      Trainings
                    </button>
                    <button onClick={handleSeeMyJobApplications}>
                      Applied Jobs
                    </button>
                  </div>
                </div>
                {/* Personal Information Section */}
                <div className="profile-section">
                  <div
                    className="section-header"
                    onClick={() => toggleSection("personalInfo")}
                  >
                    <h2>Personal Information</h2>
                    {isPersonalInfoOpen ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </div>

                  {isPersonalInfoOpen && (
                    <div
                      className="section-content"
                      style={{ textAlign: "left" }}
                    >
                      {profileData && (
                        <>
                          {/* Profile Picture Section */}
                          {!isCameraOpen && (
                            <>
                              {profilePicture ? (
                                <div className="profile-picture-container">
                                  <img
                                    src={
                                      `${profilePicFolder}/${profilePicture}` ||
                                      `${profilePicFolder}/defaultPIC.jpg`
                                    } // Fallback to a default image
                                    alt=""
                                    className="profile-picture"
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    margin: "10px 0",
                                  }}
                                >
                                  <img
                                    alt=""
                                    src={
                                      profilePicture
                                        ? `${profilePicFolder}/${profilePicture}`
                                        : `${profilePicFolder}/defaultPIC.jpg`
                                    }
                                    style={{
                                      width: "120px",
                                      height: "120px",
                                      borderRadius: "50%",
                                      objectFit: "cover",
                                      border: "2px solid #642D92",
                                      boxShadow:
                                        "0 4px 8px rgba(0, 0, 0, 0.2)",
                                    }}
                                  />
                                </div>
                              )}
                            </>
                          )}
                          <div className="profile-picture-container">
                            <div className="row">
                              <div className="col-sm-12">
                                {/* Camera Section */}
                                {isCameraOpen && (
                                  <div className="camera-container">
                                    <video
                                      ref={videoRef}
                                      autoPlay
                                      playsInline
                                      className="camera-feed"
                                      muted
                                      style={{
                                        width: "100%",
                                        height: "400px",
                                        border: "1px solid #ccc",
                                      }}
                                    />
                                    <button
                                      onClick={captureSelfie}
                                      className="capture-button"
                                    >
                                      Capture
                                    </button>
                                    <button
                                      onClick={stopCamera}
                                      className="cancel-button"
                                    >
                                      Cancel
                                    </button>
                                    <canvas
                                      ref={canvasRef}
                                      style={{ display: "none" }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="row" style={{ display: "flex" }}>
                              <div className="col-sm-6">
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={handleProfilePictureChange}
                                  className="upload-button"
                                  style={{
                                    width: "60%",
                                    marginRight: "10px",
                                  }}
                                />
                              </div>
                              <div className="col-sm-6">
                                <button
                                  onClick={startCamera}
                                  className="upload-button"
                                >
                                  Take a Selfie
                                </button>
                              </div>
                            </div>
                          </div>
                          <p>
                            {/* Toggle Switches */}
                            {profileData.registrationType ===
                              "home-owner" && (
                                <div>
                                  <strong>Actively Hiring:</strong>
                                  <Switch
                                    checked={isActivelyLooking}
                                    onChange={handleActivelyLookingToggle}
                                    color="primary"
                                  />
                                </div>
                              )}
                            {(profileData.registrationType === "chef" ||
                              profileData.registrationType === "gardener" ||
                              profileData.registrationType ===
                              "house-help") && (
                                <div className="styledDiv">
                                  <strong>Actively Looking for a Job:</strong>
                                  <Switch
                                    checked={isActivelyLooking}
                                    onChange={handleActivelyLookingToggle}
                                    color="primary"
                                  />
                                </div>
                              )}
                          </p>
                          {/* Dropdown to select Registration Type */}
                          <div
                            className="styledDiv"
                            style={{
                              display: "flex",
                              justifyContent: "left",
                            }}
                          >
                            <strong>Registered As:</strong>{" "}
                            <select
                              value={profileData.registrationType}
                              onChange={(e) => {
                                // Handle registration type change here
                                const newRegistrationType = e.target.value;
                                // Assuming you have a function to update the profile or state
                                updateRegistrationType(newRegistrationType);
                              }}
                              style={{ width: "60%" }}
                            >
                              <option value={houseHelp}>House Help</option>
                              <option value={homeOwner}>Home Owner</option>
                              <option value={Chef}>Chef</option>
                              <option value={Gardener}>gardener</option>
                            </select>
                          </div>
                          <div className="styledDiv">
                            {profileData.registrationType === homeOwner ? (
                              <div>
                                <strong>Current Budget (KES):</strong>{" "}
                                <input
                                  type="number"
                                  defaultValue={
                                    profileData.employerRates || ""
                                  }
                                  style={{ width: "60%" }}
                                  onChange={(e) =>
                                    handleSetNewRates(
                                      e.target.value,
                                      "employer"
                                    )
                                  }
                                />
                              </div>
                            ) : (
                              <div>
                                <strong>Desired Pay:</strong>{" "}
                                <input
                                  type="number"
                                  defaultValue={
                                    profileData.employeeRates || ""
                                  }
                                  style={{ width: "60%" }}
                                  onChange={(e) =>
                                    handleSetNewRates(
                                      e.target.value,
                                      "employee"
                                    )
                                  }
                                />
                              </div>
                            )}
                            {newRates && (
                              <>
                                <button
                                  style={{
                                    marginTop: "10px",
                                    marginRight: "10px",
                                    padding: "8px 15px",
                                    backgroundColor: "#642D92",
                                    color: "white",
                                    border: "none",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    handleRatesChange(newRates, userType);
                                  }}
                                >
                                  Update
                                </button>
                                <button
                                  style={{
                                    marginTop: "10px",
                                    marginRight: "10px",
                                    padding: "8px 15px",
                                    backgroundColor: "#DE1777",
                                    color: "white",
                                    border: "none",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => setNewRates("")}
                                >
                                  Cancel
                                </button>
                              </>
                            )}
                          </div>

                          <div className="styledDiv">
                            <strong>Name:</strong>{" "}
                            {profileData.fullName || "N/A"}
                          </div>
                          <div className="styledDiv">
                            <strong>Email:</strong>{" "}
                            {profileData.email || "N/A"}
                          </div>
                          <div className="styledDiv">
                            <strong>Phone Number:</strong>{" "}
                            {profileData.phoneNumber || "N/A"}
                          </div>

                          <div className="styledDiv">
                            <strong>Date Joined:</strong>{" "}
                            {profileData.timeStamp || "N/A"}
                          </div>
                          <div className="styledDiv">
                            <strong>Date Of Birth:</strong>{" "}
                            {profileData.dateOfBirth || "N/A"}
                          </div>
                          <div className="styledDiv">
                            <strong>Country:</strong>{" "}
                            {profileData.selectedCountry || "N/A"}
                          </div>
                          <div className="styledDiv">
                            <strong>Status:</strong>{" "}
                            {profileData.status || "N/A"}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>

                {/* About Me Section */}
                <div
                  className="profile-section"
                  style={{ textAlign: "left" }}
                >
                  <div
                    className="section-header"
                    onClick={() => toggleSection("aboutMe")}
                  >
                    <h2>More About Me</h2>
                    {isAboutMeOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </div>
                  {isAboutMeOpen && (
                    <div className="section-content">
                      <>
                        <div className="styledDiv">
                          <strong>Availability:</strong>
                          {availability || "Not provided."}
                        </div>
                        <div className="styledDiv">
                          <strong>Years Of Experience:</strong>
                          {yearsOfExperience || "Not provided."}
                        </div>
                        <div className="styledDiv">
                          <strong>Preferred Age Groups: </strong>

                          {(() => {
                            if (Array.isArray(preferredAgeGroups)) {
                              return preferredAgeGroups.length > 0
                                ? preferredAgeGroups.join(", ")
                                : "Not provided";
                            }
                            if (typeof preferredAgeGroups === "string") {
                              try {
                                const parsedGroups =
                                  JSON.parse(preferredAgeGroups);
                                if (Array.isArray(parsedGroups)) {
                                  return parsedGroups.length > 0
                                    ? parsedGroups.join(", ")
                                    : "Not provided";
                                }
                              } catch (error) {
                                // If it's not valid JSON, just return the string as is
                                return preferredAgeGroups || "Not provided";
                              }
                            }
                            return "Not provided";
                          })()}
                        </div>
                        <div className="styledDiv">
                          <strong>Current Employment Status: </strong>
                          {currentEmploymentStatus || "Not provided."}
                        </div>
                        <div className="styledDiv">
                          <strong>Languages Spoken: </strong>
                          {languagesSpoken || "Not provided."}
                        </div>
                        <div className="styledDiv">
                          <strong>criminal Record: </strong>
                          {criminalRecord || "Not provided."}
                        </div>
                        {criminalRecordExplanation && (
                          <>
                            <div className="styledDiv">
                              <strong>criminal Record Explanation: </strong>
                              {criminalRecordExplanation || "Not provided."}
                            </div>
                          </>
                        )}
                        <div className="styledDiv">
                          <strong>Description: </strong>
                          {description || "No description provided."}
                        </div>
                        {/* Display the selected values in a styled container */}
                        <div className="styledDiv">
                          <strong>Selected Skills:</strong>{" "}
                          {skills != "" && skills.length > 0
                            ? skills.join(", ")
                            : "No skills selected"}
                        </div>
                        <h3 style={{ textAlign: "left", padding: "16px 0" }}>Next of Kin:</h3>
                        {nextOfKin.map((kin, index) => (
                          <div key={index} style={{margin: "16px 0"}}>
                            <TextField
                              label={`Kin ${index + 1} Full Name`}
                              value={kin.fullName}
                              type="tel"
                              required
                              fullWidth
                              InputProps={{ readOnly: true }} // Read-only
                            />
                            <div className="roleSelection" style={{margin: "16px 0"}}>
                              <select
                                className="role-dropdown"
                                value={kin.relationShip}
                                disabled // Disable the dropdown
                              >
                                <option value="">
                                  Relationship Not Provided
                                </option>
                                <option value="Brother">Brother</option>
                                <option value="Sister">Sister</option>
                                <option value="Cousin">Cousin</option>
                                <option value="Mother">Mother</option>
                                <option value="Father">Father</option>
                                <option value="Auntie">Auntie</option>
                                <option value="Uncle">Uncle</option>
                                <option value="Guardian">Guardian</option>
                                <option value="Other">Other</option>
                              </select>
                            </div>
                            <TextField
                              label={`Kin ${index + 1} Phone Number`}
                              value={kin.phoneNumber}
                              type="tel"
                              required
                              fullWidth
                              InputProps={{ readOnly: true }} // Read-only
                            />
                          </div>
                        ))}
                        <h3 style={{ textAlign: "left" }}>Referees:</h3>
                        {referees.map((referee, index) => (
                          <div key={index} style={{margin: "16px 0"}}>
                            <TextField
                              label={`Referee ${index + 1} Full Name`}
                              value={referee.fullName}
                              type="tel"
                              required
                              fullWidth
                              InputProps={{ readOnly: true }} // Read-only
                            />
                            <TextField
                              label={`Referee ${index + 1} Phone Number`}
                              value={referee.phoneNumber}
                              type="tel"
                              required
                              fullWidth
                              InputProps={{ readOnly: true }} // Read-only
                              sx={{
                                margin: "16px 0"
                              }}
                            />
                          </div>
                        ))}
                        <h3 style={{ textAlign: "left" }}>
                          Uploaded Documents
                        </h3>
                        <div
                          style={{
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {!idPicture && !certificatePicture && (
                            <div className="row">
                              <div className="col-sm-12">
                                <p style={{ textAlign: "left" }}>
                                  No documents Uploaded
                                </p>
                              </div>
                            </div>
                          )}

                          <div className="row mt-3">
                            <div className="col-sm-6">
                              {idPicture && (
                                <>
                                  <label>Id Picture (Front):</label>
                                  <img
                                    src={`${IdPicFolder}/${idPicture}`}
                                    alt=""
                                    style={{
                                      width: "400px",
                                      marginRight: "10px",
                                    }}
                                  />
                                </>
                              )}
                              <div></div>
                            </div>
                            <div className="col-sm-6">
                              {idPictureBack && (
                                <>
                                  <label>ID Picture (Back):</label>
                                  <img
                                    src={`${IdPicFolder}/${idPictureBack}`}
                                    alt=""
                                    style={{
                                      width: "400px",
                                      marginRight: "10px",
                                    }}
                                  />
                                </>
                              )}
                              <div></div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div>
                                {certificatePicture && (
                                  <>
                                    <label>Certificate Picture:</label>
                                    <img
                                      src={`${certificatePicFolder}/${certificatePicture}`}
                                      alt=""
                                      style={{ width: "400px" }}
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {userDetails && (
                          <button onClick={() => handleEdit(0)}>
                            Edit Profile Infomation
                          </button>
                        )}
                        {!userDetails && (
                          <button onClick={() => handleEdit(0)}>
                            Complete Profile
                          </button>
                        )}

                        {/* Disable button */}
                      </>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <p>Loading...</p>
              </>
            )}
          </div>
        </div>
      </div>

      <MpesaPaymentModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSuccess={handleOnSuccess}
        connectId={0}
        regType={profileData.registrationType}
      />

      <IdVerificationModal
        isOpen={showIdVerificationModal}
        onClose={handleCloseIdVerificationModal}
        onIdVerificationSuccess={onIdVerificationSuccess}
        connectId={""}
      />
    </>
  );
};

export default Profile;
