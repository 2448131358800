import React, { useEffect, useState } from "react";
import "../CSS/JobApplicationModal.css";
import { Modal } from "@mui/material";
import { postDataToAPI } from "../Shared/shared";
import { getLocalStorageVariable } from "./NavBar";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const JobApplicationModalConfirmation = ({
  isOpen,
  onClose,
  jobId,
  onSuccess,
}) => {
  const navigate = useNavigate();
  const [jobDetails, setJobDetails] = useState(null);
  const userEmail = getLocalStorageVariable("userEmail");

  useEffect(() => {
    const fetchJobDetails = async () => {
      if (!jobId) return; // Ensure jobId is valid

      const formData = new FormData();
      formData.append("jobId", jobId);
      try {
        const response = await postDataToAPI(
          "/dashboard/fetchJobDetailsById.php",
          formData
        );

        if (response) {
          setJobDetails(response[0]);
        }
      } catch (error) {
        console.error("Error fetching job details:", error);
      }
    };

    fetchJobDetails();
  }, [jobId]);

  //Fetch Existing Profile details

  const handleJobApply = async () => {
    const formData = new FormData();
    formData.append("jobId", jobId);
    formData.append("userEmail", userEmail);

    const response = await postDataToAPI(
      "/dashboard/applyForJob.php",
      formData
    );

    if (response === "success") {
      onSuccess();
      Swal.fire("Success", "Job Application Success");
      navigate("/jobList");
      //Send Email
      const formData = new FormData();
      formData.append("email", userEmail);
      formData.append("role", jobDetails.role);
      formData.append("location", jobDetails.jobLocation);
      formData.append("expectedPay", jobDetails.pay);

      const res = await postDataToAPI("/Emails/sendPostJobApplicationEmail.php", formData);
      if(res){}

    } else {
      Swal.fire("Error", "Job Application Failed");
    }
  };

  if (!jobDetails) {
    return null; // Return null or a loading indicator while data is being fetched
  }

  return (
    <Modal open={isOpen} className="viewConnectLays">
      <div
        style={{
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "8px",
          maxWidth: "500px",
          margin: "50px auto",
          textAlign: "center",
        }}
      >
        <h3 style={{ marginBottom: "20px" }}>
          Confirm Job Details And Submit Application
        </h3>
        <div style={{ textAlign: "left", marginBottom: "20px" }}>
          <p>
            <strong>Role:</strong> {jobDetails.role}
          </p>
          <p>
            <strong>Description:</strong> {jobDetails.description}
          </p>
          <p>
            <strong>Location:</strong> {jobDetails.jobLocation}
          </p>
          <p>
            <strong>Pay:</strong> Ksh {jobDetails.pay}
          </p>
          <p>
            <strong>Employment Type:</strong> {jobDetails.employmentType}
          </p>
          <p>
            <strong>Number of Days:</strong> {jobDetails.numberOfDays}
          </p>
          <p>
            <strong>Skills Needed:</strong>{" "}
            {JSON.parse(jobDetails.skills).join(", ")}
          </p>
          <p>
            <strong>Status:</strong> {jobDetails.status}
          </p>
          <p>
            <strong>Email:</strong> {jobDetails.email}
          </p>
          <p>
            <strong>Posted On:</strong> {jobDetails.timeStamp}
          </p>
        </div>
        <p>
          <button
            style={{
              backgroundColor: "#4caf50",
              color: "#fff",
              border: "none",
              padding: "10px 15px",
              borderRadius: "4px",
              cursor: "pointer",
              marginRight: "10px",
            }}
            onClick={handleJobApply}
          >
            Confirm And Apply
          </button>
          <button
            style={{
              backgroundColor: "#f44336",
              color: "#fff",
              border: "none",
              padding: "10px 15px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={onClose}
          >
            Modify My Information First
          </button>
        </p>
      </div>
    </Modal>
  );
};

export default JobApplicationModalConfirmation;
