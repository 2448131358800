import React from "react";
import "../CSS/terms.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Terms = () => {
  const navigate = useNavigate();

  return (
    <div className="terms-container">
      <div className="terms-header">
        <h3>Terms and Conditions of Use for Nannies Desk App</h3>
        <p>Effective Date: [1/12/2024]</p>
      </div>

      <div className="terms-content">
        <p>
          Welcome to the Nannies Desk App. By downloading, installing, or using the App, you agree to abide by these Terms and Conditions. If you do not agree to these Terms, please uninstall the App and discontinue its use immediately.
        </p>

        <h4>1. Introduction</h4>
        <p>
          Nannies Desk is a digital platform connecting Nannies with Employers. While we verify Users' identification and provide Nannies with training, we do not take responsibility for their conduct, actions, or outcomes once employers engage Nannies.
        </p>

        <h4>2. Acceptance of Terms</h4>
        <p>
          By accessing or using the App, you confirm that you have read, understood, and agreed to these Terms. You also agree to comply with all applicable laws and regulations while using the App.
        </p>

        <h4>3. User Eligibility</h4>
        <p><strong>3.1 Age Restriction:</strong> You must be 18 to create an account and use the App.</p>
        <p><strong>3.2 Registration Requirements:</strong> Users are required to provide accurate and verifiable information, including a valid identification document, during registration.</p>
        <p><strong>3.3 Verification:</strong> The App verifies identification documents during registration to promote a safe environment but does not guarantee Users' trustworthiness or suitability.</p>

        <h4>4. Services Provided</h4>
        <p><strong>4.1:</strong> The App facilitates communication and connections between Employers and Nannies.</p>
        <p><strong>4.2:</strong> Nannies Desk offers training programs to equip Nannies with essential skills for domestic work.</p>
        <p><strong>4.3:</strong> The App does not mediate contracts, payments, or agreements between Employers and Nannies.</p>

        <h4>5. User Responsibilities</h4>
        <p><strong>5.1 Employers:</strong></p>
        <ul>
          <li>They must conduct their due diligence before hiring a nanny.</li>
          <li>Ensure fair treatment of Nannies and comply with applicable labor laws.</li>
        </ul>
        <p><strong>5.2 Nannies:</strong></p>
        <ul>
          <li>Provide truthful and accurate information in their profiles.</li>
          <li>Fulfill agreed job responsibilities with professionalism.</li>
        </ul>

        <h4>6. Disclaimer of Liability</h4>
        <p><strong>6.1:</strong> Nannies Desk acts solely as a platform for facilitating connections and does not guarantee the conduct, suitability, or performance of either Nannies or Employers.</p>
        <ul>
          <li>Any disputes, losses, or damages arising from interactions between Nannies and Employers.</li>
          <li>Actions, behaviors, or misconduct of any User after engagement.</li>
        </ul>

        <h4>7. KYC Documentation</h4>
        <p><strong>7.1:</strong> All registered Users must provide Know Your Customer (KYC) documentation, including valid ID and proof of residence.</p>
        <p><strong>7.2:</strong> Verified KYC documentation will only be shared with relevant authorities or stakeholders when required by law.</p>

        <h4>8. Prohibited Activities</h4>
        <ul>
          <li>Providing false or misleading information during registration or profile creation.</li>
          <li>Using the App for fraudulent, illegal, or harmful purposes.</li>
          <li>Harassing, abusing, or discriminating against other Users.</li>
        </ul>

        <h4>9. Privacy and Data Protection</h4>
        <p><strong>9.1:</strong> The App collects and processes User data in accordance with our Privacy Policy, which complies with applicable data protection laws.</p>
        <p><strong>9.2:</strong> By using the App, you consent to the collection, storage, and use of your data as outlined in the Privacy Policy.</p>

        <h4>10. Dispute Resolution</h4>
        <p><strong>10.1:</strong> Users are responsible for independently resolving any disputes arising from their engagement.</p>
        <p><strong>10.2:</strong> Nannies Desk may provide relevant information, such as verified KYC documents, to assist in resolving disputes upon formal request.</p>

        <h4>11. Account Suspension and Termination</h4>
        <p><strong>11.1:</strong> Nannies Desk reserves the right to suspend or terminate a User’s account for violations of these Terms or any activity that compromises the safety or integrity of the platform.</p>
        <p><strong>11.2:</strong> Users may deactivate their accounts through the App at any time.</p>

        <h4>12. Updates to the Terms</h4>
        <p>Nannies Desk reserves the right to modify these Terms at any time. Updates will be communicated through the App, and continued use after changes are made constitutes acceptance of the revised Terms.</p>

        <h4>13. Governing Law</h4>
        <p>The laws of Kenya govern these Terms. Any disputes arising under these Terms shall be resolved exclusively in the courts.</p>

        <h4>14. Contact Information</h4>
        <p>For inquiries, feedback, or support, contact us:</p>
        <ul>
          <li>Email: info@nanniesdesk.com</li>
          <li>Phone: +254722525689</li>
        </ul>

        <p>
          By using the Nannies Desk App, you confirm that you have read, understood, and agreed to these Terms and Conditions.
        </p>
      </div>

      <div className="terms-footer">
        <Button
          style={{ backgroundColor: "#DE1877", color: "white", borderRadius: "40px", padding: "8px 32px" }}
          variant="contained"
          onClick={() => navigate("/register")}
        >
          Back
        </Button>
      </div>
    </div>
  );
};

export default Terms;
