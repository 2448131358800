import React, { useState } from "react";
import logo from "../../dashboardTemplate/assets/brand/logo.png";
import Swal from "sweetalert2";
import { postDataToAPI } from "../../Shared/shared";
import '../../CSS/Footer.css';

export const FooterTop = () => {
  const initialState = {
    name: "",
    email: "",
  };
  const [{ name, email }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataa = new FormData();
    formDataa.append("name", name);
    formDataa.append("userMail", email);
    const responsee = await postDataToAPI(
      "/subscriptions/subscribe.php",
      formDataa
    );

    if (responsee === "success") {
      clearState();
      Swal.fire({
        title: "Success",
        text: "subscribed successfully!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } else {
      Swal.fire({
        title: "Error",
        text: "Failed to subscribe, try again later",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <div id="subscribe" className={"footer-top-section-container"}>
      <div className="footer-top-section-inner">
        <div className="far-left-section">
          <img src={logo} alt={"Logo"} className="logo" />
          <p className={"far-left-section-description"}>At Nannies Desk, we aim to revolutionize the domestic workforce by providing a
            digital platform that bridges the gap between families and Nanniesdevice.</p>
          <div className={"footer-contact-info"}>
            <i className={"bi bi-telephone-fill"}></i>
            <p>Any Queries : +254722525689</p>
          </div>
        </div>
        <div className={"mid-left-section"}>
          <p>Download Available Now</p>
          <a href="#" className={"mobile-app-link"}>
            <i class="bi bi-apple"></i>
            <p>App Store</p>
          </a>
          <a href="#" className={"mobile-app-link"}>
            <i class="bi bi-google-play"></i>
            <p>Play Store</p>
          </a>
        </div>
        <div className={"mid-right-section"}>
          <p>Links</p>
          <ul>
            <li><a href="#">- Home</a></li>
            <li><a href="#">- Featured Caregiver</a></li>
            <li><a href="#servicess">- Key Features</a></li>
            <li><a href="#blog">- Blog</a></li>
            <li><a href="#team">- Team</a></li>
            <li><a href="#contact">- Contact</a></li>
          </ul>
        </div>
        <div className={"far-right-section"}>
          <p>News Letter</p>
          <form onSubmit={handleSubmit}>
            <input type={"text"} placeholder="Name" value={name} id={"name"} name={"name"} required onChange={handleChange} className="name-input-field"/>
            <input type={"email"} placeholder="Email" value={email} id={"email"} name={"email"} required onChange={handleChange} className={"email-address-input-field"} />
            <button type={"submit"}>SUBSCRIBE</button>
          </form>
        </div>
      </div>
    </div>
  );
};
