import React, { useEffect, useState } from "react";
import { getLocalStorageVariable, postDataToAPI } from "../Shared/shared";
import { useLocation } from "react-router-dom";

function MyTrainings() {
  const location = useLocation();
  const data = location.state || {};
  const email = data.email || "";

  const userEmail = email || getLocalStorageVariable("userEmail");

  const [myTrainings, setMyTrainings] = useState([]); // Stores training records
  const [myTrainingsFullDetails, setMyTrainingsFullDetails] = useState([]); // Stores detailed training information

  // Fetch training records
  useEffect(() => {
    const fetchTrainings = async () => {
      const formData = new FormData();
      formData.append("email", userEmail);

      const response = await postDataToAPI(
        "/dashboard/fetchTrainingsUndergone.php",
        formData
      );

      if (response) {
        setMyTrainings(response); // Set training records (id, email, trainingId, timestamp)
      }
    };

    fetchTrainings();
  }, [userEmail]);

  // Fetch detailed information for each training
  useEffect(() => {
    if (myTrainings.length === 0) {
      return;
    }

    const fetchTrainingsDetails = async () => {
      const detailsPromises = myTrainings.map(async (training) => {
        const formData = new FormData();
        formData.append("id", training.trainingId);

        const response = await postDataToAPI(
          "/dashboard/fetchTrainingsFullDetails.php",
          formData
        );

        return response;
      });

      const allDetails = await Promise.all(detailsPromises);
      setMyTrainingsFullDetails(allDetails.filter((detail) => detail));
    };

    fetchTrainingsDetails();
  }, [myTrainings]);

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-sm-12">
            <h3>Undertaken Trainings:</h3>
            {myTrainingsFullDetails && myTrainingsFullDetails.length > 0 ? (
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Target Group</th>
                    <th>Time undertaken</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {myTrainingsFullDetails.map((trainingArray, index) => (
                    <tr key={index}>
                      <td>{trainingArray[0]?.title || "N/A"}</td>
                      <td>{trainingArray[0]?.targetGroup || "N/A"}</td>
                      <td>{myTrainings[index]?.timeStamp || "N/A"}</td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            window.open(trainingArray[0]?.link, "_blank")
                          }
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No trainings found.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyTrainings;
