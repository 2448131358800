import React, { useEffect, useState } from "react";
import {
  Modal,
  TextField,
  Button,
  CircularProgress,
  Typography,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import "../CSS/MpesaPaymentModal.css";
import { postDataToAPI } from "../Shared/shared";
import Swal from "sweetalert2";
import { getLocalStorageVariable } from "./NavBar";
import { locations, userSkills } from "../Shared/sharedLists";

const AboutInfoModal = ({ isOpen, onClose, registrationType, onSuccess }) => {
  const [description, setDescription] = useState("");
  const userEmail = getLocalStorageVariable("userEmail");
  const [skills, setSkills] = useState([]);
  const [preferredLocations, setPreferredLocations] = useState([]);

  const [nextOfKin, setNextOfKin] = useState([
    { fullName: "", relationShip: "", phoneNumber: "" },
    { fullName: "", relationShip: "", phoneNumber: "" },
  ]);

  const [referees, setReferees] = useState([
    { fullName: "", phoneNumber: "" },
    { fullName: "", phoneNumber: "" },
  ]);

  //UseEffect To fetch already existing data and map to state
  useEffect(() => {
    const fetchAboutInfo = async () => {
      const userEmail = getLocalStorageVariable("userEmail");

      const formData = new FormData();
      formData.append("email", userEmail);
      formData.append("Type", "aboutInfo");

      const userInfoDataa = await postDataToAPI(
        "/fetchAdditionalInfoData.php",
        formData
      );

      if (userInfoDataa) {
        const userInfoData = userInfoDataa[0];
        setDescription(userInfoData.about);
        setNextOfKin(JSON.parse(userInfoData.nextOfKin));
        setReferees(JSON.parse(userInfoData.referees));
        if (userInfoData.preferredLocations) {
          setPreferredLocations(userInfoData.preferredLocations.split(","));
        } else {
          setPreferredLocations([]); // Optional: Clear the state if no data is provided
        }

        if (userInfoData.skills) {
          setSkills(userInfoData.skills.split(","));
        } else {
          setSkills([]); // Optional: Clear the state if no data is provided
        }
      }
    };

    fetchAboutInfo();
  }, []);

  const handleSave = async () => {
    const formData = new FormData();
    formData.append("email", userEmail);
    formData.append("about", description);
    formData.append("referees", JSON.stringify(referees));
    formData.append("nextOfKin", JSON.stringify(nextOfKin));
    formData.append("preferredLocations", preferredLocations);
    formData.append("skills", skills);

    const response = await postDataToAPI("/updateAboutInfo.php", formData);

    if (response === "Success") {
      onSuccess();
      Swal.fire("Success", "Profile updated successfully!", "success");
    } else {
      Swal.fire("Failed!", "Profile update Failed", "error");
    }
  };

  const handleSkillsChange = (event, newSkills) => {
    setSkills(newSkills);
  };

  const handlePreferedWorkLocations = (event, newWorkLocations) => {
    setPreferredLocations(newWorkLocations);
  };

  const handleNextOfKinChange = (index, field, value) => {
    const updatedNextOfKin = [...nextOfKin];
    updatedNextOfKin[index][field] = value;
    setNextOfKin(updatedNextOfKin);
  };

  const handleRefereesChange = (index, field, value) => {
    const updatedReferees = [...referees];
    updatedReferees[index][field] = value;
    setReferees(updatedReferees);
  };

  const skillChoice =
    registrationType === "chef"
      ? userSkills.chef_skills
      : registrationType === "house-help"
      ? userSkills.nanny_skills
      : registrationType === "gardener"
      ? userSkills.gardener_skills
      : [];

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className="modals">
        <h2 style={{ fontWeight: "bold" }}>Update About Info:</h2>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Describe yourself..."
        />

        <Autocomplete
          multiple
          options={skillChoice}
          value={skills}
          onChange={handleSkillsChange}
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox checked={selected} />
              {option}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              type="tel"
              {...params}
              variant="outlined"
              label={`Select Skills`}
            />
          )}
        />
        <Autocomplete
          multiple
          options={locations.kenyan_counties}
          value={preferredLocations}
          onChange={handlePreferedWorkLocations}
          getOptionLabel={(option) => option}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox checked={selected} />
              {option}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              type="tel"
              variant="outlined"
              label={`Select Preferred Work Locations`}
            />
          )}
        />
        <h3>Next of Kin</h3>
        {nextOfKin.map((kin, index) => (
          <div key={index}>
            <TextField
              label={`Kin ${index + 1} Full Name`}
              value={kin.fullName}
              onChange={(e) =>
                handleNextOfKinChange(index, "fullName", e.target.value)
              }
              type="tel"
              required
              fullWidth
            />
            <div className="roleSelection">
              <select
                className="role-dropdown"
                value={kin.relationShip}
                onChange={(e) =>
                  handleNextOfKinChange(index, "relationShip", e.target.value)
                }
                required
              >
                <option value="">Select who he/she is to you.</option>
                <option value="Brother">Brother</option>
                <option value="Sister">Sister</option>
                <option value="Cousin">Cousin</option>
                <option value="Mother">Mother</option>
                <option value="Father">Father</option>
                <option value="Auntie">Auntie</option>
                <option value="Uncle">Uncle</option>
                <option value="Guardian">Guardian</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <TextField
              label={`Kin ${index + 1} Phone Number`}
              value={kin.phoneNumber}
              onChange={(e) =>
                handleNextOfKinChange(index, "phoneNumber", e.target.value)
              }
              type="tel"
              required
              fullWidth
            />
          </div>
        ))}
        <h3>Referees</h3>
        {referees.map((referee, index) => (
          <div key={index}>
            <TextField
              label={`Referee ${index + 1} Full Name`}
              value={referee.fullName}
              type="tel"
              onChange={(e) =>
                handleRefereesChange(index, "fullName", e.target.value)
              }
              required
              fullWidth
            />
            <TextField
              label={`Referee ${index + 1} Phone Number`}
              value={referee.phoneNumber}
              type="tel"
              onChange={(e) =>
                handleRefereesChange(index, "phoneNumber", e.target.value)
              }
              required
              fullWidth
            />
          </div>
        ))}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          style={{ marginTop: "20px" }}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onClose}
          style={{
            marginTop: "20px",
            marginLeft: "10px",
            backgroundColor: "black",
            color: "white",
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default AboutInfoModal;
