import { TextField } from "@mui/material";
import React, { useState } from "react";
import { Chef, Gardener, homeOwner, houseHelp } from "../Shared/sharedLists";
import { getLocalStorageVariable, postDataToAPI } from "../Shared/shared";
import Swal from "sweetalert2";

function PostTrainingMaterial() {
  const [title, setTitle] = useState("");
  const [targetGroup, setTargetGroup] = useState("");
  const [link, setLink] = useState("");
  const [description, setDescription] = useState("");
  const userEmail = getLocalStorageVariable("userEmail");

  const handlePostTrainingResource = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("email", userEmail);
    formData.append("title", title);
    formData.append("targetGroup", targetGroup);
    formData.append("link", link);
    formData.append("description", description);

    const response = await postDataToAPI(
      "/dashboard/postTrainingMaterial.php",
      formData
    );

    console.log("Response: ", response);

    if (response === "Success") {
      setTitle("");
      setTargetGroup("");
      setLink("");
      setDescription("");

      Swal.fire({
        title: "Success!",
        text: "Training Resource Created Successfully",
        icon: "success",
        timer: 3000,
      });
    } else {
      Swal.fire({
        title: "Failed!",
        text: "Resource Creation Failed, try again later",
        icon: "error",
        timer: 3000,
      });
    }
  };

  return (
    <>
      <div style={{ width: "100%", padding: "0", margin: "0" }}>
        <div style={{ maxWidth: "1200px", margin: "0 auto", padding: "20px" }}>
          <div style={{ marginBottom: "20px" }}>
            <form onSubmit={handlePostTrainingResource}>
              <div>
                <select
                  value={targetGroup}
                  onChange={(e) => setTargetGroup(e.target.value)}
                  required
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "5px",
                    marginBottom: "15px",
                    border: "1px solid #ccc",
                  }}
                >
                  <option value="">Select target group</option>
                  <option value={homeOwner}>Home Owners</option>
                  <option value={Gardener}>gardener</option>
                  <option value={houseHelp}>house help</option>
                  <option value={Chef}>Chef</option>
                </select>
              </div>
              <TextField
                type="text"
                label="Enter Resource Title"
                variant="outlined"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                fullWidth
                style={{ marginBottom: "15px" }}
              />
              <TextField
                type="text"
                label="Enter Resource Link"
                variant="outlined"
                value={link}
                onChange={(e) => setLink(e.target.value)}
                required
                fullWidth
                style={{ marginBottom: "15px" }}
              />
              <textarea
                placeholder="Write a short description about the Resource"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  marginBottom: "15px",
                  minHeight: "100px",
                  minWidth: "100%",
                }}
              />
              <button
                type="submit"
                style={{
                  color: "#fff",
                  padding: "10px 20px",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  width: "100%",
                }}
              >
                Create Training Material
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default PostTrainingMaterial;
