import React, { useEffect, useState } from "react";
import {
  CAvatar,
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import {
  cilBell,
  cilCreditCard,
  cilCommentSquare,
  cilEnvelopeOpen,
  cilFile,
  cilLockLocked,
  cilSettings,
  cilTask,
  cilUser,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";

import { Link } from "react-router-dom";
import { getLocalStorageVariable, postDataToAPI } from "../../../Shared/shared";
import { server } from "../../../configs/configs";
const profilePicFolder = `${server}/profilePics`;

const AppHeaderDropdown = () => {
  const [notificationsCount, setNotificationsCount] = useState(0);
  const userEmail = getLocalStorageVariable("userEmail");
  const [profileData, setProfileData] = useState({});
  const [profilePicture, setProfilePicture] = useState("");

  const fetchUserData = async () => {
    const userEmail = getLocalStorageVariable("userEmail");
    const users = await postDataToAPI("/dashboard/fetchAllUsers.php", {});
    const user = users.find((u) => u.email === userEmail);

    if (user) {
      setProfileData(user);
      setProfilePicture(user.profilePic || "");
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchNumber = async () => {
      const formData = new FormData();
      formData.append("email", userEmail);
      formData.append("status", "unread");

      const res = await postDataToAPI(
        "/dashboard/fetchTotalNumberOfNotifications.php",
        formData
      );

      if (res) {
        setNotificationsCount(res.count);
      }
    };
    fetchNumber();
  }, []);

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle
        placement="bottom-end"
        className="p-0"
        caret={false}
      >
        <CAvatar
        style={{border: "1px solid white", overflow: "hidden"}}
          src={
            profilePicture
              ? `${profilePicFolder}/${profilePicture}`
              : `${profilePicFolder}/defaultPIC.jpg`
          }
          size="md"
        />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-body-secondary fw-semibold mb-0">
          Account
        </CDropdownHeader>
        <CDropdownHeader className="bg-body-secondary fw-semibold mb-2">
          Hi, {profileData.fullName}
        </CDropdownHeader>
        <CDropdownItem href="#" className="pl-3 d-flex justify-content-start align-items-center">
          <CIcon icon={cilBell} className="me-2" />
          <Link to="/notifications">Notifications</Link>
          <CBadge color="info" className="ms-2">
            {notificationsCount > 0 && <span>{notificationsCount}</span>}
          </CBadge>
        </CDropdownItem>
        <CDropdownItem className="pl-3 d-flex justify-content-start align-items-center">
          <CIcon icon={cilUser} className="me-2" />
          <Link to="/profile">Profile</Link>
        </CDropdownItem>
        {/* <CDropdownItem href="#">
          <CIcon icon={cilCreditCard} className="me-2" />
          Payments
          <CBadge color="secondary" className="ms-2"></CBadge>
        </CDropdownItem> */}
        <CDropdownDivider className="w-100"/>
        <CDropdownItem>
          <CIcon icon={cilLockLocked} className="me-2" />
          <Link to="/logout" style={{color: "red"}}>Log Out</Link>
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;
