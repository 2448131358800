import React, { useState, useEffect } from "react";
import { getLocalStorageVariable, postDataToAPI } from "../Shared/shared";
import "../CSS/notifications.css";

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [status, setStatus] = useState("unread");
  const [loading, setLoading] = useState(true);
  const userEmail = getLocalStorageVariable("userEmail");

  // Fetch notifications from the API based on the selected status
  const fetchNotifications = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("email", userEmail);
    formData.append("status", status);

    try {
      const response = await postDataToAPI(
        "/dashboard/fetchNotifications.php",
        formData
      );

      // Check if the response is an array or an object containing the notifications array
      if (response && Array.isArray(response)) {
        setNotifications(response);
      } else if (
        response &&
        response.notifications &&
        Array.isArray(response.notifications)
      ) {
        // If the response contains a `notifications` field with an array
        setNotifications(response.notifications);
      } else if (response) {
        setNotifications([response]);
      } else {
        setNotifications([]);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setLoading(false);
    }
  };

  // Function to mark notification as read
  const markAsRead = async (notificationId) => {
    const formData = new FormData();
    formData.append("email", userEmail);
    formData.append("status", "read");

    try {
      const response = await postDataToAPI(
        "/dashboard/modifyNotifications.php",
        formData
      );
      if (response === "Success") {
        // Update the local state to reflect the read status
        setNotifications((prevNotifications) =>
          prevNotifications.map((notification) =>
            notification.id === notificationId
              ? { ...notification, status: "read" }
              : notification
          )
        );
      } else {
        //console.error("Failed to update notification status");
      }
    } catch (error) {
      //console.error("Error marking notification as read:", error);
    }
  };

  // Fetch notifications when the component mounts or status changes
  useEffect(() => {
    fetchNotifications();
  }, [status]);

  // Handle status change (filter by unread, read, etc.)
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-sm-12">
            <div className="status-filter">
              <label htmlFor="status">Filter by status:</label>
              <select style={{width: "100%", height: "30px"}} id="status" value={status} onChange={handleStatusChange}>
                <option value="unread">Unread</option>
                <option value="read">Read</option>
              </select>
            </div>

            {loading ? (
              <p>Loading notifications...</p>
            ) : (
              <ul className="notification-list">
                {notifications.length > 0 ? (
                  notifications.map((notification) => (
                    <li
                      key={notification.id}
                      className={
                        notification.status === "unread" ? "unread" : "read"
                      }
                    >
                      <h4>{notification.subject}</h4>
                      <div>{notification.message}</div>
                      <small>{notification.timestamp}</small>
                      <br></br>
                      {notification.status === "unread" && (
                        <button
                          style={{ height: 40 }}
                          onClick={() => markAsRead(notification.id)}
                        >
                          Mark as Read
                        </button>
                      )}
                    </li>
                  ))
                ) : (
                  <p>No notifications found.</p>
                )}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
