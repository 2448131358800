import React from "react";
import bg from "./bg/Bg.png";
import { useNavigate } from "react-router-dom";

export const JoinCommunity = () => {
  const navigate = useNavigate();

  return (
    <div id="JoinCommunity">
      <div
        className="container-fluid"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          textAlign: "center",
          padding: "60px",
          color: "white",
        }}
      >
        <div className="row">
          <div className="col-sm-12">
            <h4 className="mb-4" style={{fontWeight: '600'}}>Join our community today!</h4>
            <p>
              We understand that choosing someone to care for your child is a
              decision that’s deeply rooted in trust. 
            </p>
            <p className="mb-4">That’s why we go the extra
            mile to ensure that every caregiver on our platform is vetted.</p>
            <button
              onClick={() => navigate("/login")}
              style={{
                width: "160px",
                height: "50px",
                border: "1px solid #FFFFFF",
                borderRadius: "5px",
                opacity: 1,
                backgroundColor: "transparent",
                color: "#FFFFFF", // Text color
                cursor: "pointer",
                transition: "0.5s linear ease-in"
              }}
              className="get-started-button"
            >
              GET STARTED
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
