import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { postDataToAPI } from "../Shared/shared";
import NotificationsIcon from "@mui/icons-material/Notifications";

// Utility function to get items from local storage
export const getLocalStorageVariable = (name) => {
  const value = localStorage.getItem(name);
  return value;
};

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Hook to get the current route
  const userEmail = getLocalStorageVariable("userEmail");

  const [notificationsCount, setNotificationsCount] = useState(0);

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a className="navbar-brand page-scroll" href="/dashboard">
            Nannies Desk
          </a>
        </div>
        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li className="nav-item">
              <span
                className="notification-icon"
                style={{
                  position: "relative",
                  cursor: "pointer",
                  color: "white",
                }}
                onClick={() => navigate("/notifications")}
              >
                <NotificationsIcon
                  fontSize="large"
                  style={{ marginTop: "11px", fontSize: "30px" }}
                />
                {notificationsCount > 0 && (
                  <span
                    className="badge"
                    style={{
                      position: "absolute",
                      top: "0",
                      right: "0",
                      backgroundColor: "red",
                      color: "white",
                      borderRadius: "50%",
                      padding: "0.2em 0.5em",
                      fontSize: "0.8em",
                    }}
                  >
                    {notificationsCount}
                  </span>
                )}
              </span>
            </li>
            <li>
              <Link
                to="/dashboard"
                style={{
                  color:
                    location.pathname === "/dashboard" ? "yellow" : "white",
                }}
              >
                Home
              </Link>
            </li>
            {userEmail === "nanniesdesk@gmail.com" && (
              <li>
                <Link
                  to="/adminDashboard"
                  style={{
                    color:
                      location.pathname === "/adminDashboard"
                        ? "yellow"
                        : "white",
                  }}
                >
                  Admin
                </Link>
              </li>
            )}
            <li>
              <Link
                to="/seePosts"
                style={{
                  color: location.pathname === "/seePosts" ? "yellow" : "white",
                }}
              >
                Blog
              </Link>
            </li>
            <li>
              <Link
                to="/profile"
                style={{
                  color: location.pathname === "/profile" ? "yellow" : "white",
                }}
              >
                Profile
              </Link>
            </li>
            <li>
              <Link
                to="/kyc"
                style={{
                  color: location.pathname === "/kyc" ? "yellow" : "white",
                }}
              >
                More (KYC)
              </Link>
            </li>
            <li>
              <Link
                to="/logout"
                style={{
                  color: location.pathname === "/logout" ? "yellow" : "red",
                }}
              >
                Logout
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
